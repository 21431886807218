import styled from 'styled-components'

import theme from '../../../theme/theme'

import { responsiveFontSize } from '../../../util'

const Button = styled.a`
    background-color: ${props => props.bgColor ? props.bgColor : theme.colors.buttons};
    border-radius: 1.5rem;
    box-shadow: 0 15px 35px 0 ${theme.colors.buttonsShadow};
    color: ${props => props.fontColor ? props.fontColor : theme.colors.black};
    cursor: pointer;
    font-family: ${theme.fonts.primary};
    font-size: ${props => props.fontSize ? props.fontSize : '16px'};
    font-weight: 600;
    margin-left: ${props => props.marginLeft ? props.marginLeft : '0'};
    max-height: 42px;
    margin-right: ${props => props.marginRight ? props.marginRight : '0'};
    margin-bottom: 1em; 
    max-width: ${props => props.maxWidth ? props.maxWidth : 'unset'};
    padding: 0.5em 1.90em;
    text-decoration: none;
    transition: 0.4s;
    text-align: center;

    &:hover,
    &:focus {
        background-color: ${props => props.hoverBg ? props.hoverBg : theme.colors.buttonsHovered};
        color: ${props => props.hoverTextColor ? props.hoverTextColor : theme.colors.black};
    }

    @media (max-width: ${theme.breakPoints.small}) {
        font-size: ${props => props.fontSize ? responsiveFontSize(props.fontSize, 10) : responsiveFontSize('16px', 10)};
        margin-left: ${props => props.smallMarginLeft ? props.smallMarginLeft : '0'};
        margin-right: ${props => props.smallMarginRight ? props.smallMarginRight : '0'};
        margin-bottom: ${props => props.smallMarginBottom ? props.smallMarginBottom : '0'};
    }
`

export default Button
