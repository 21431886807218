import styled from 'styled-components'

import theme from '../../../theme/theme'

export const Title = styled.h5`
    font-family: ${theme.fonts.secondary};
    font-size: 24px;
    font-weight: 900;
    margin-bottom: 0.5em;
`

export const List = styled.ul`
    list-style: none;
    margin: 0;
    margin-bottom: 1em;
    padding: 0;
`

export const Item = styled.li`
    line-height: 1.2em;
    margin-bottom: 0;
`

export const Anchor = styled.a`
    color: ${theme.colors.black};
    font-family: ${theme.fonts.primary};
    font-size: 16px;
    text-decoration: none;
    transition: all 0.4s;

    &:focus,
    &:hover {
        color: ${theme.colors.crusta};
    }
`