import React from 'react'
import PropTypes from 'prop-types'

import {
	List,
	Item,
	ItemContent,
	Question,
	Answer,
	MaskAnswer,
	ToggleBTn,
} from './collapsibleStyles'

class Collapsible extends React.Component {
	constructor(props) {
		super(props)


		this.state = {
			list: this.props.list,
			answers: new Array(this.props.list.length).fill(null),
			questions: new Array(this.props.list.length).fill(false),
			openedAnswer: false
		}

		this.toggleQuestion = this.toggleQuestion.bind(this)
	}

	toggleQuestion(i) {
		const array = new Array(this.state.list.length).fill(false)

		array[i] = !this.state.questions[i]

		this.setState({
			questions: array,
			openedAnswer: this.state.answers[i].clientHeight,
		})
	}

	render() {
		const {questions, answers} = this.state
		const {list} = this.props

		return (
			<List>
				{list && list.map((item, i) => {
					return (
						<Item className={questions[i] ? 'opened' : false} onClick={this.toggleQuestion.bind(this, i)} key={i}>
							<ItemContent>
								<Question>{item.question}</Question>
								<MaskAnswer className={questions[i] ? 'opened' : false} openedAnswer={this.state.openedAnswer}>
									<Answer ref={el => {answers[i] = el; return }}>{item.answer}</Answer>
								</MaskAnswer>
							</ItemContent>
							<ToggleBTn className={this.state.questions[i] ? 'opened' : false}>{this.state.questions[i] ? '–' : '+'}</ToggleBTn>
						</Item>
					)
				}
				)}
			</List>
		)
	}
}

Collapsible.propTypes = {
	list: PropTypes.array
}

Collapsible.defaultProps = {
	list: []
}

export default Collapsible
