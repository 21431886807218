import React from 'react'
import PropTypes from 'prop-types'
import MediaQuery from 'react-responsive'
import ReCAPTCHA from "react-google-recaptcha"

import {
	Container,
	Row,
	Column,
} from '../../layouts'

import {
	Title,
	CheckBox,
	Subtitle,
	Button,
	Input,
	LoadingData,
	Link
} from '../'

import {
	FormContainer,
	P as Paragraph
} from './NewOrgFormStyles'

import theme from '../../../theme/theme'

class OneTimeDonate extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		const { trial_agree, authority_agree, processing_agree } = this.props
		return (
			<FormContainer>
				<Container>
					{
						this.props.freeTrial &&
						<CheckBox
							style={{ margin: 0, marginLeft: -15 }}
							onChange={this.props.handleCheck}
							defaultSelected={trial_agree}
							type="checkbox"
							value={Number(trial_agree)}
							name={'trial_agree'}
							label={'I agree to a one (1) month free trial.'}
						/>
					}
					{
						this.props.freeTrial &&
					<CheckBox
						style={{ margin: 0 }}
						type="checkbox"
						onChange={this.props.handleCheck}
						defaultSelected={processing_agree}
						value={Number(processing_agree)}
						name={'processing_agree'}
						label={'I agree to allow Flourish to process payments at no cost to me for the 30 day trial period.'}
					/>
					}
					<CheckBox
						onChange={this.props.handleCheck}
						type="checkbox"
						defaultSelected={authority_agree}
						value={Number(authority_agree)}
						name={'authority_agree'}
						label={'I certify that I am the person that can make financial decisions and sign agreements on behalf of my organization.'}
					/>
					<Row>
						<Column>
							{this.props.errors && <Subtitle color="red" fontSize={'21px'} marginLeft={'0'}>{this.props.errors}</Subtitle>}
						</Column>
					</Row>
					{this.props.pending ?
						<Row style={{ justifyContent: "center", alignItems: "center" }}>
							<LoadingData/>
						</Row>
						:
						<div>
							<Row style={{ justifyContent: 'center', alignItems: 'center' }}>
								<ReCAPTCHA
									ref={this.props.recaptchaRef}
									sitekey="6LcnNXEUAAAAAGB_61p6XakVsgYAylJNkzXTNYV0"
								/>
							</Row>
							<Row style={{ justifyContent: "center", alignItems: "center" }}>
								<Button onClick={this.props.onBack} marginRight={'20px'} maxWidth={'150px'} hoverBg={theme.colors.salomie}>Back</Button>
								<Button onClick={this.props.onSubmit}  maxWidth={'150px'} hoverBg={theme.colors.salomie}>Submit</Button>
							</Row>
							<Row style={{ alignItems: 'center', justifyContent: 'center' }}>
								<Paragraph> By clicking submit, I agree to the
									<Link style={{ display: 'inline' }} href="http://files.flourishchange.com/files/Marketing/Website/Free_Trial_Flourish_TOS.pdf"> terms of service</Link> and
                  conditions set in the <Link style={{ display: 'inline' }} href="/privacy">privacy policy.</Link></Paragraph>
							</Row>
						</div>
					}
				</Container>
			</FormContainer>
		)
	}
}

OneTimeDonate.propTypes = {
	submitBtn: PropTypes.string,
	errors: PropTypes.any,
	valid: PropTypes.bool,
	submit: PropTypes.func,
	loading: PropTypes.bool,
	onSubmit: PropTypes.func,
	onBack: PropTypes.func,
	handleCheck: PropTypes.func,
	handleFormEdit: PropTypes.func,
	recaptchaRef: PropTypes.any,
	captchaChange: PropTypes.func,
	pending: PropTypes.bool,
	defaultValues: PropTypes.object,
	trial_agree: PropTypes.bool,
	authority_agree: PropTypes.bool,
	processing_agree: PropTypes.bool,
	freeTrial: PropTypes.bool
}

export default OneTimeDonate
