import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import '../assets/scss/main.scss'
import Modal from 'react-responsive-modal'

import theme from '../theme/theme'
import {mobileRedirect, checkMobileBrowser} from '../util'

import {
	TopBar,
	BurgerBtn,
	MobileMenu,
	Footer,
	Title,
	Paragraph,
	Button
} from './ui'

class Layout extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: 'is-loading',
			toggleMenu: true,
			didMount: false,
			showModal: false
		}
		this.toggleMenuTrigger = this.toggleMenuTrigger.bind(this)
	}

	componentWillMount() {
		mobileRedirect()
	}

	componentDidMount() {
		const modalURLS = ['checkout', 'pricing', 'nonprofits']

		for (var path of modalURLS) {
			console.log('here')
			if (window && window.location.href.indexOf(path) > 0) {
				// eslint-disable-next-line
				this.setState({
					showModal: false,
				})
			}
		}

		this.timeoutId = setTimeout(() => {
			this.setState({loading: ''})
		}, 100)

		// eslint-disable-next-line
		this.setState({
			didMount: true,
		})
	}

	componentWillUnmount() {
		if (this.timeoutId) {
			clearTimeout(this.timeoutId)
		}
	}

	toggleMenuTrigger(toggle) {
		this.setState({
			toggleMenu: toggle
		})
	}

	render() {
		const { children } = this.props
		const {showModal} = this.state
		return (
			<div className={`body ${this.state.loading}`}>
				<Helmet
					title="Meet Flourish"
				>
					<meta name="description" content="Pocket change for global change. Through our mobile and desktop applications, Flourish helps nonprofits fundraise by capturing recurring donations and is able to deliver consistent revenues to our nonprofit clients." />
					<meta name="keywords" content="fundraising tips for nonprofits, fundraising methods and techniques, ways to fundraise for non profit,raise donors, fundraising techniques, how to fundraise for a non profit, ways to fundraise for non profit, round up, roundup, round up app, roundup app"/>
					<meta name="theme-color" content={theme.colors.crusta} />
					<meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
					{this.props.headComponents}
				</Helmet>
				{checkMobileBrowser &&
					<BurgerBtn toggleMenu={this.toggleMenuTrigger.bind(this)} />
				}
				{checkMobileBrowser &&
				<MobileMenu toggleMenu={this.state.toggleMenu}/>
				}
				<TopBar style={{ background: theme.colors.TopBar }}>Meet Flourish</TopBar>
				{children}
				<Footer />
				<Modal
					open={showModal} onClose={()=>{window.location = "https://roundupapp.com"}} center>
					<div style={{ maxWidth: '90%' }}>
						<Title fontSize={'50px'}>
							Breaking News!
						</Title>
						<Paragraph
							fontSize={'21px'}
							style={{ maxWidth: '100%' }}
							fontWeight={'200'}>Flourish recently bought RoundUp App, becoming the largest RoundUp Donation company in the country, serving thousands of nonprofits, and tens of thousands of donors. All new organizations are being asked to register via RoundUp App.</Paragraph>
						<Button
							onClick={()=>{window.location = "https://roundupapp.com/"}}
						>Go To RoundUpApp.com</Button>
					</div>
				</Modal>
			</div>
		)
	}
}

Layout.propTypes = {
	headComponents: PropTypes.object,
	children: PropTypes.object
}

export default Layout
