import styled from 'styled-components'

const Link = styled.a`
    display: inline-block;
    cursor: pointer;
    margin-left: ${props => props.marginLeft ? props.marginLeft : 'auto'};
    margin-right: ${props => props.marginRight ? props.marginRight : 'auto'};
    max-width: ${props => props.maxWidth ? props.maxWidth : 'unset'};
    width: 100%;

    :hover {
      color: ${props => props.hoverColor ? props.hoverColor : props.bgColor};
      cursor: ${props => props.onClick && 'pointer'};
      text-decoration: underline
    }
`

export default Link
