export default {
	content: {
		title: "Frequently Asked Questions",
		paragraph: "If these don't answer your questions, please contact us at <a href=\"mailto:hello@flourishchange.com\" title=\"hello@flourishchange.com\">hello@flourishchange.com</a>.",
	},
	questions: [
		{
			question: "Do both credit cards and debit cards work?",
			answer: "Yes, all debit and credit cards linked to a banking institution work. A user links their debit account first and then is able to add as many different accounts as he has banks to best track all of their purchases.",
		},
		{
			question: 'Can Flourish ever log into my bank?',
			answer: 'Your login credentials will never be made accessible to Flourish. When linking your debit or credit card accounts, we prompt you to login to your bank account directly; therefore, we never actually see your username or password. This information is never stored.'
		},
		{
			question: "At what threshold will you withdraw money out of someone’s account?",
			answer: "Once the micro-donations aggregate to at least $5, Flourish will transfer the money out of a user’s account.",
		},
		{
			question: "What about tax receipts?",
			answer: "If an organization has partnered with Flourish, we provide a tax reciept for your donations on their behalf. If they haven't partnered, as indicated by our logo, then we unfortunately cannot garantee tax receipts."
		},
		{
			question: "Does a nonprofit have to be partnered with Flourish in order to support them through the app?",
			answer: "You can use the app to give to any nonprofit organization that is registered with the IRS. However, certain features, such as one-click relationships and funding specific projects, are reserved for organizations that are partnered with us.",
		},
		{
			question: "Where is the money being held? What institution is handling the transactions?",
			answer: "All donations flow into an escrow account through our banking partner and are then redistributed to the nonprofits.",
		},
		{
			question: "What about privacy? What care is in place to prevent identity theft?",
			answer: "Our user data is stored on an encrypted server. We operate using a tokenization method that allows us to minimize risk and maximize security.",
		},
		{
			question: "If a nonprofit onboards with Flourish, how long to implementation?",
			answer: "Once an organization signs a contract, the onboarding process is very quick and simple. An organization will be featured on the app within 48 hours of signing the contract. However, the complete onboarding process consists of onboarding training sessions to illustrate our administrative features.",
		},
		{
			question: "What would be your suggested accrued amount to send as an ACH to an organization?",
			answer: "We are flexible and can send donations to organizations when their funds in escrow reach any amount; however, it is highly dependent on the organization’s operating budget. We generally recommend no less than $500/transfer, but can accommodate an organization's needs as they arise ( i.e. if a large project ends and the funding is needed immediately). In addition, organizations can request funds at anytime.",
		},
	],
}
