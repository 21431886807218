import styled from 'styled-components'

import theme from '../../../theme/theme'

export const Container = styled.div`
	align-items: center;
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.dustyGray};
    border-radius: 4px;
	display: flex;
	flex-direction: row;
    height: ${props => props.height ? props.height : '61px'};
    margin-bottom: 1em;
    padding: 0.5em 1em;
`

export const Select = styled.select.attrs({
	name: props => props.nameField,
	required: props => props.required,
})`
    appearance: none;
    background: transparent;
    border: none;
	cursor: pointer;
    font-family: ${theme.fonts.primary};
    font-size: 16px;
	height: 100%;
    outline: none;
    width: 100%;

    &:focus {
        outline: none;
    }
`

export const Icon = styled.i`
	height: 25px;
`