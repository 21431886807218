const scrollTo = (element) => {
	const body = document.documentElement

	const count = element.offsetTop - body.scrollTop - 70

	if (typeof body.scroll !== "undefined") {
		body.scroll({top: count, left: 0, behavior: 'smooth'})
	} else {
		element.scrollIntoView()
	}
}

export default scrollTo