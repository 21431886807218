import React from 'react'
import PropTypes from 'prop-types'

import {
	Container,
	Label,
	Input,
	Group,
	EyeIcon,
} from './inputFieldStyles'

class InputField extends React.Component {
	constructor (props) {
		super(props)

		this.state = {
			viewPass: false,
		}

		this.switchPass = this.switchPass.bind(this)
	}

	switchPass () {
		this.setState({
			viewPass: !this.state.viewPass
		})
	}

	render () {
		return (
			<Container>
				{this.props.label && <Label>{this.props.label}</Label>}
				<Group>
					<Input nameField={this.props.name} fieldType={this.props.type} viewPass={this.state.viewPass} onChange={this.props.onChange} min={this.props.min} max={this.props.max} required={this.props.required} />
					{(this.props.type && this.props.type == 'password') && <EyeIcon className="fa fa-eye" onClick={this.switchPass} />}
				</Group>
			</Container>
		)
	}
}

InputField.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
	type: PropTypes.string,
	min: PropTypes.string,
	max: PropTypes.string,
	required: PropTypes.string,
	onChange: PropTypes.func,
}

export default InputField
