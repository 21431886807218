export default {
	content: {
		title: "Privacy Policy",
		paragraph: `
            <p><strong>Flourish Change Inc. Website Privacy Policy</strong></p>
            <h6>PLEASE READ THIS POLICY CAREFULLY.</h6>
            <p>Flourish Change Inc. is committed to safeguarding the confidentiality and privacy of information entrusted to
                it, including any personal information (sometimes referred to as \"personally identifiable information\" or
                \"PII\") collected through its websites. Your continued use of this website constitutes your agreement with this
                Privacy Policy and any subsequent updates. Please see the additional information under the heading [Legal] for more
                information about our on-line terms of use and policies in general.</p>
            <h6>Who to Contact About Your Personal Information</h6>
            <p>If you have any questions about our use of your Personal Information you can e-mail<br>hello@flourishchange.com
                or write to:<br>Flourish Change Inc.<br>Attn: Legal<br>6900 Dallas Parkway, Suite 200<br>Plano, TX 75024</p>
            <h6>What Types of Personal Information is Collected</h6>
            <p>The personally identifiable information that may be collected might include your name, address, e-mail address, and
                telephone number. Additional background information might be collected in connection with specific inquiries.</p>
            <p>In addition, Flourish Change Inc. may collect certain types of information that you do not visibly enter, such
                as your IP address, browsing patterns on our websites, click stream data, and HTP protocol elements. This tracking
                information is stored in anonymous, aggregated and non-personal format, and is used to understand and analyze
                trends, to administer the websites, and to learn about user behavior on the websites. However, Flourish Change, Inc.
								 may use IP addresses to help identify you when Flourish Change Inc. feels, in its sole discretion, that
                it is necessary to enforce compliance with this Privacy Policy, to protect its services, websites, systems,
                information, employees, business partners, subsidiaries, affiliates, users, customers or others, or when required
                by law or for law enforcement purposes.</p>
            <h6>Purpose for Collecting Personal Information</h6>
            <p>We use this Personal Information to:</p>
            <ul>
                <li>Communicate with you and others as part of our business;</li>
                <li>Send you important information;</li>
                <li>Make decisions about whether to provide services which we offer;</li>
                <li>Carry out research and analysis, including analysis of our customer base and other individuals whose Personal
                    Information we collect as explained in this Privacy Policy;</li>
                <li>Provide marketing information to you;</li>
                <li>Personalize your experience when using our websites;</li>
                <li>Manage our infrastructure and business operations, and comply with internal policies and procedures, including
                    those relating to auditing; finance and accounting; billing and collections; IT systems; data and website
                    hosting; data analytics; business continuity; and records, document and print management;</li>
                <li>Comply with applicable laws and regulatory; and</li>
                <li>Establish and defend legal rights; protect our operations or those of any of our group companies or business
                    partners, our rights, privacy, safety or property, and/or that of our group companies, you or others; and
                    pursue available remedies or limit our damages.</li>
            </ul>
            <h6>Sharing of Personal Information</h6>
            <p>Flourish Change Inc. may make Personal Information available to:</p>
            <ul>
                <li>
                    <h6>Our group companies</h6>Other Flourish Change Inc. companies may have access to and use of Personal
                    Information in connection with the conduct of our business where appropriate.
                </li>
                <li>
                    <h6>Our service providers</h6>External third-party service providers, such as accountants, actuaries, auditors,
                    experts, lawyers and other outside professional advisors; IT systems, support and hosting service providers;
                    printing, advertising, marketing and market research and analysis service providers; banks and financial
                    institutions that service our accounts; third-party claim administrators; document and records management
                    providers; and similar third-party vendors and outsourced service providers that assist us in carrying out
                    business activities.
                </li>
                <li>
                    <h6>Governmental authorities and third parties involved in court action</h6>Flourish Change Inc. may also
                    share Personal Information with governmental or other public authorities (including, but not limited to,
                    courts, law enforcement, and tax authorities; and third-party civil legal process participants and their
                    accountants, auditors, lawyers and other advisors and representatives as we believe to be necessary or
                    appropriate: (a) to comply with applicable law, including laws outside your country of residence; (b) to comply
                    with legal process; (c) to respond to requests from public and government authorities including public and
                    government authorities outside your country of residence; (d) to enforce our terms and conditions; (e) to
                    protect our operations or those of any of our group companies; (f) to protect our rights, privacy, safety or
                    property, and/or that of our group companies, you or others; and (g) to allow us to pursue available remedies
                    or limit our damages.
                </li>
            </ul>
            <h6>Information Security</h6>
            <p>Flourish Change Inc. has reasonable security policies and procedures in place to protect personal information
                from unauthorized loss, misuse, alteration, or destruction. Flourish Change Inc. will take appropriate
                technical, physical, legal and organizational measures, which are consistent with applicable privacy and data
                security laws.</p>
            <p>Despite Flourish Change Inc.’s best efforts, no data transmission over the Internet or data storage system can
                be guaranteed to be 100% secure. If you have reason to believe that your interaction with us is no longer secure
                (for example, if you feel that the security of any Personal Information you might have with us has been
                compromised), please immediately notify us.</p>
            <h6>Personal Information of Other Individuals</h6>
            <p>If you provide Personal Information to Flourish Change Inc. regarding other individuals/entities, you agree:
                (a) to inform the other individual/entity about the content of this Privacy Policy, and any other applicable
                Privacy Notice provided to you; and (b) to obtain any legally-required consent of Personal Information about the
                individual/entity in accordance with this Privacy Notice and other Privacy Notice.</p>
            <h6>Access</h6>
            <p>If you have submitted personal information to Flourish Change Inc., under most circumstances you have the right
                to reasonable access to that data to correct any inaccuracies. You can also make a request to update or remove
                information about you by contacting hello@flourishchange.com, and we will make all reasonable and practical
                efforts to comply with your request, so long as it is consistent with applicable law and professional standards.</p>
            <h6>California Rights</h6>
            <p>If you are a California resident, California Civil Code Section 1798.83 permits you to request information regarding
                the disclosure of your PII by Flourish Change Inc. to a third party for the third party's direct marketing
                purposes. Flourish Change Inc., does not share, sell, rent or trade your PII with any third parties for
                promotional purposes. To make such a request, please contact us using the contact information provided below.</p>
            <h6>Other Information We Collect Through our Websites</h6>
            <p>\"Other Information\" is any information that does not reveal your specific identity, such as:</p>
            <ul>
                <li>Browser and device information;</li>
                <li>Information collected through cookies, pixel tags and other technologies;</li>
                <li>Demographic information and other information provided by you; and</li>
                <li>Aggregated information</li>
            </ul>
            <h6>How We Collect Other Information</h6>
            <p>We and our third-party service providers may collect Other Information in a variety of ways, including:</p>
            <ul>
                <li><strong>Through your browser or device:</strong> Certain information is collected by most websites or
                    automatically through your device, such as your IP address (i.e., your computer’s address on the internet),
                    screen resolution, operating system type (Windows or Mac) and version, device manufacturer and model, language,
                    internet browser type and version, time of the visit, and page(s) visited. We use this information to ensure
                    that the Websites function properly.</li>
                <li><strong>Using cookies; online tracking:</strong>
                    <p>Cookies are pieces of information stored directly on the device you are using. Cookies allow us to recognize
                        your device and to collect information such as internet browser type, time spent on the Websites, pages
                        visited, language preferences, country website preference. We may use the information for security
                        purposes, to facilitate navigation, to display information more effectively, or to personalize your
                        experience while using the Websites. In addition, we may use the information to gather statistical
                        information about the usage of the Websites in order to understand how they are used, continually improve
                        their design and functionality, and assist us with resolving questions about them. Cookies further allow us
                        to present to you the advertisements or offers that are most likely to appeal to you. We may also use
                        cookies to track your responses to our advertisements and we may use cookies or other files to track your
                        use of other websites. We do not respond to browser do-not-track signals at this time.</p>
                    <p>Third parties may collect information about your use of the Websites or online services.</p><strong>Below is
                        a description of some of the cookies we may use on the Site, what they do, what data about you they collect
                        and what we may use them for:</strong>
                </li>
                <li><strong>Geo Location cookie:</strong> When a visitor visits the Site for the first time we read their location
                    from their IP address and use this information to assume the country site that they want to visit. This method
                    is not entirely accurate, however, so when they navigate to a particular country site we use a cookie to store
                    this information and to store the language that they chose to view the site. The next time they visit the Site
                    we read the cookie and present the same country and language version they used on their last visit. This has
                    the intended benefit that they don’t need to reselect the country site they need each time they visit a Flourish Change, Inc.
										website. No Personal Information is collected or used by the cookie.</li>
                <li><strong>Site Catalyst cookie (by Adobe):</strong> This cookie enables us to collect and analyze data about how
                    visitors arrive at the Site and then how they interact with the Site, including products they may search,
                    content they may view, and the steps leading up to a completed or abandoned sale. We use this aggregated
                    information to adapt the Site to better serve their and other users’ needs and interests, and to provide more
                    relevant and useful information. The cookie is placed on a visitor’s PC on a temporary basis only. The cookie
                    does not collect or use their Personal Information. Instead, it logs an anonymous code which identifies users
                    and this code \"follows\" the user in their journey through the Site. We use the aggregated and anonymous
                    information for statistical analysis.</li>
                <li><strong>DoubleClick cookies:</strong> We place a tracking pixel cookie on to all Site visitors’ computers for
                    the purpose of our banner advertising activity. We use these cookies to know that a visitor has been on the
                    Site before which allows us to display an appropriate banner ad on to an affiliated network website. No
                    Personal Information is collected. One of the advertisement companies that we use is Google, Inc, trading as
                    DoubleClick. For more information on the DoubleClick cookie, or to opt out from the DoubleClick advertisement
                    cookie please visit: <a href="http://www.google.com/privacy/ads/">http://www.google.com/privacy/ads/ </a>. </li>
                        <li><strong>Optimost cookie:</strong> Optimost is a service provided by Hewlett-Packard to analyze customer
                        behavior when visiting the Site. This service allows us to test variations of our website pages or elements
                        within the pages. The tests will usually result in improvements to the website and the user experience. The
                        cookie has an anonymous \"visitor ID\" which is a random number generated to identify a visitor, to
                        distinguish between unique and/or repeat visitors. These cookies do not collect Personal Information. To
                        view the Optimost privacy policy, please visit: <a href="https://asp.optimost.com/avatar/privacy-policy">https://asp.optimost.com/avatar/privacy-policy
                            </a>. </li> <li><strong>Oracle cookie:</strong> We use the services of <a href=\"www.oracle.com\">www.oracle.com</a>
                            to serve a persistent cookie on visitors’ browsers when they first visit our web pages, unless there is
                            already an Oracle cookie on their browser because they have visited a non-Flourish Change, Inc.
                            site which is also using Oracle’s services. However, visitors’ browsing activity on our web pages will
                            not be combined with visitors’ browsing behavior on other websites using Oracle’s services. We use
                            visitors’ browsing activity on our web pages to understand which pages and content our visitors use or
                            do not use so that we can monitor Site content to best meet our visitors' needs. For further
                            information please read the Oracle privacy policy.</li>
            </ul>
            <p>You can refuse to accept the cookies we use by adjusting your browser settings. However, if you do not accept these
                cookies, you may experience some inconvenience in your use of the Site and some online products.</p>
            <ul>
                <li><strong>Using pixel tags, web beacons, clear GIFs or other similar technologies:</strong>
                    <p>These may be used in connection with some Websites and HTML-formatted e-mail messages to, among other
                        things, track the actions of users of the Websites and email recipients, measure the success of our
                        marketing campaigns and compile statistics about usage of the Websites and response rates. We use Adobe’s
                        Omniture analytics service, which uses cookies and web beacons to help us understand more about how our
                        website is used by consumers so we can continue to improve it. Adobe does not have the right to use the
                        information we provide to them beyond what is necessary to assist us. For more information on Adobe’s
                        Omniture service, including how to Opt out, go to: <a href="http://www.omniture.com/privacy/policy#optout">http://www.omniture.com/privacy/policy#optout
                            </a>. </p>We may use behavioral advertising service providers to customize, target and report on Flourish Change, Inc.
														advertisements served across the web. To do this, these service providers use
                            cookies, pixel tags and other technologies to collect information about your and other users’ use of
                            our Site and third-party sites. If you would like to opt out of having your Site usage information used
                            for these purposes, visit the selfregulatory program opt-out pages at: <a href="www.aboutads.info/choices/"">www.aboutads.info/choices/
                            </a> or <a href="www.networkadvertising.org/managing/opt_out.asp">www.networkadvertising.org/managing/opt_out.asp
                            </a>. </li> <li><strong>Using your physical location:</strong> We may collect the physical location of
                            your device to provide you with personalized location-based services and content. We may also share
                            your device’s physical location, combined with information about what advertisements you viewed and
                            other information we collect, with our marketing partners to enable them to provide you with more
                            personalized content and to study the effectiveness of advertising campaigns. In some instances, you
                            may be permitted to allow or deny such uses and/or sharing of your device’s location, but if you choose
                            to deny such uses and/or sharing, we and/or our marketing partners may not be able to provide you with
                            the applicable personalized services and content.
                </li>
                <li><strong>Using information provided by you:</strong> Some information (for example, your location or preferred
                    means of communication) is collected when you voluntarily provide it. Unless combined with Personal
                    Information, this information does not personally identify you.</li>
                <li><strong>By aggregating information:</strong> We may aggregate and use certain information (for example, we may
                    aggregate information to calculate the percentage of our users who have a particular telephone area code).</li>
            </ul>
            <p>Please note that we may use and disclose Other Information for any purpose, except where we are required to do
                otherwise under applicable law. If we are required to treat Other Information as Personal Information under
                applicable law, or if we combine Other Information with Personal Information, then, in addition to the uses listed
                in the \"Other Information We Collect\" section above, we may use and disclose Other Information for all the
                purposes for which we use and disclose Personal Information.</p>
            <h6>Third Party Privacy Practices</h6>
            <p>Flourish Change Inc. Websites may contain links to other sites, including websites maintained by Flourish Change, Inc.
							affiliates that are governed by other privacy policies that may differ somewhat from this one.
                Users should review the privacy policy of each website visited before disclosing any personal information. To the
                extent that we provide links to third-party websites, such links do not constitute an endorsement, sponsorship, or
                recommendation by Flourish Change Inc. of the third parties, the third party websites, or the information
                contained on those websites, and Flourish Change Inc. is not responsible or liable for your use of such third
                party websites.</p>
            <h6>Children</h6>
            <p>Flourish Change Inc.’s websites are not intentionally designed for or directed at children under the age of 18.
                It is our policy never to knowingly collect or maintain information about anyone under the age of 18 and we request
                that these individuals do not provide Personal Information through the Flourish Change Inc. Websites.</p>
            <h6>Changes to this Policy</h6>
            <p>Flourish Change Inc. reserves the right to make changes to this Privacy Policy from time to time. We will
                notify you by posting amendments to the Privacy Policy on this website.</p>
            <h6>Questions and Comments</h6>
            <p>If you have questions or concerns regarding this policy or Flourish Change Inc.' personal data processing
                policies, please contact us at: <a href="mailto:hello@flourishchange.com">hello@flourishchange.com</a>.</p>
        `,
	}
}
