import styled from 'styled-components'

import theme from '../../../theme/theme'

const Column =  styled.div`
    ${props => props.bgImage ? 'background-image: url(' + props.bgImage + ');' : ''}
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: ${props => props.row ? 'row' : 'column'};
    flex-wrap: wrap;
    height: ${props => props.height ? props.height : 'auto'};
    ${props => props.vAlign == 'center' ? 'justify-content: center;' : false}
    ${props => props.vAlign == 'bottom' ? 'justify-content: flex-end;' : false}
    ${props => props.vAlign == 'between' ? 'justify-content: space-between;' : false}
    ${props => props.vAlign == 'end' ? 'justify-content: flex-end;' : false}
    max-width: ${props => props.maxWidth ? props.maxWidth : '100%'};
    padding: 0 ${props => props.lateralPaddings ? props.lateralPaddings : '15px'};
    position: relative;
    width: 100%;

    @media (max-width: ${theme.breakPoints.small}) {
        max-width: ${props => props.smallMaxWidth ? props.smallMaxWidth : '100%'};
        margin: 0 auto;
        margin-bottom: ${props => props.smallMarginBottom ? props.smallMarginBottom : ''};
    }

    @media (max-width: ${theme.breakPoints.xsmall}) {
        max-width: ${props => props.xsmallMaxWidth ? props.xsmallMaxWidth : '100%'};
    }
`

export default Column
