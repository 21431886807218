import styled from 'styled-components'

import theme from '../../../theme/theme'

import { responsiveFontSize } from '../../../util'

const Paragraph = styled.p`
    color: ${props => props.color ? props.color : theme.colors.black};
    font-family: ${theme.fonts.primary};
    font-size: ${props => props.fontSize ? props.fontSize : '16px'};
    font-weight: ${props => props.fontWeight ? props.fontWeight : '300'};
    height: ${props => props.height ? props.height : 'auto'};
    line-height: ${props => props.lineHeight ? props.lineHeight : '1.5em'};
    margin-left: ${props => props.marginLeft ? props.marginLeft : '0'};
    margin-right: ${props => props.marginRight ? props.marginRight : '0'};
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : '1em'};
    max-width: ${props => props.maxWidth ? props.maxWidth : 'unset'};
    text-align: ${props => props.textAlign ? props.textAlign : 'unset'};

    a {
        color: ${theme.colors.link};
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    @media (max-width: ${theme.breakPoints.small}) {
        height: ${props => props.height ? responsiveFontSize(props.height, -20) : 'auto'};
        font-size: ${props => props.fontSize ? responsiveFontSize(props.fontSize, -10) : '16px'};
    }
`

export default Paragraph
