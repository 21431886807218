import React from 'react'
import PropTypes from 'prop-types'

import {
	Overlay,
	Image,
} from './loadingDataStyles'

const LoadingData = (props) =>
	(<Overlay padding={props.padding} align={props.align} {...props}>
		<Image maxWidth={props.maxWidth} />
	</Overlay>)

LoadingData.propTypes = {
	align: PropTypes.string,
	maxWidth: PropTypes.string,
	padding: PropTypes.string,
}

export default LoadingData