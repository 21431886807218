import React from 'react'
import MediaQuery from 'react-responsive'

import {
	Row,
	Column,
} from '../../layouts'

import {
	Title,
	Link,
} from '../'

import theme from '../../../theme/theme'


const BlogIndex = (props) =>
	<Link href={props.slug} style={{ textDecoration: 'none', color: 'black' }}>
	<MediaQuery minWidth={theme.breakPoints.small}>
		<Row>
			<Column maxWidth="25%">
				<Title style={{ margin: 0 }} fontSize={'40px'}>{props.title}</Title>
				<span fontSize={'25px'}>{props.date}</span>
			</Column>
			<Column>
				{props.blurb}
			</Column>
		</Row>
		</MediaQuery>
		<MediaQuery maxWidth={theme.breakPoints.small}>
			<Row>
				<Title style={{ margin: 0 }} fontSize={'40px'}>{props.title}</Title>
				<span fontSize={'25px'}>{props.date}</span>
			</Row>
		</MediaQuery>
	</Link>

export default BlogIndex
