export default {
	content: {
		title: "Terms and Conditions",
		paragraph: `
            <p><strong>Flourish Change Inc. Website Terms and Conditions</strong></p>
            <h6>PLEASE READ THIS POLICY CAREFULLY.</h6>
            <p>This Agreement is a legally binding agreement between you (the individual using the website and/or service) and
                Flourish Change Inc. By using, visiting and/or proceeding to establish an Account on the Flourish Change, Inc. website,
								located at www.flourishchange.com, or any other site operated by Flourish Change, Inc.
								 and/or its affiliates, collectively referred to as the "Websites", YOU SIGNIFY YOUR ASSENT
                AND TO BE BOUND BY THESE TERMS AND CONDITIONS (the "Terms") OF THIS AGREEMENT AND THE MANNER IN WHICH WE
                COLLECT, USE, AND DISCLOSE YOUR PERSONAL INFORMATION AS SET OUT IN Flourish Change Inc.’s Privacy Policy
                (the "Privacy Policy"). Terms may be changed or updated at any time. You can always read the current, complete
                version of the Terms at http://www.flourishchange.com/terms-of-service. Specific Web pages of the Flourish Change, Inc.’s
								Website may set out additional terms and conditions, all of which are incorporated by
                reference into these Terms.</p>
            <p>PLEASE READ THE AGREEMENT CAREFULLY BEFORE ESTABLISHING AN ACCOUNT OR USING THE SERVICE.</p>
            <p>NOTE: THIS AGREEMENT INCLUDES AN AGREEMENT TO <u>MANDATORY ARBITRATION</u>, WHICH MEANS
                THAT YOU AGREE TO SUBMIT ANY DISPUTE RELATED TO THE SITE, THE DOMAIN, THE SERVICE OR YOUR ACCOUNT TO BINDING
                ARBITRATION RATHER THAN PROCEEDING IN COURT. IF YOU WANT TO <u>OPT-OUT</u> OF THIS
                MANDATORY ARBITRATION AGREEMENT, THE DISPUTE RESOLUTION/ARBITRATION PROVISION BELOW DESCRIBES THE PROCEDURES
                YOU MUST FOLLOW TO DO SO. THE DISPUTE RESOLUTION/ARBITRATION PROVISION ALSO INCLUDES A <u>CLASS ACTION WAIVER</u>, WHICH MEANS THAT YOU AGREE TO PROCEED WITH ANY DISCPUTE INDIVIDUALLY AND NOT AS PART
                OF A CLASS ACTION. THIS AGREEMENT ALSO INCLUDES A JURY WAIVER.</p>
            <p>In the case of inconsistencies between these Terms and information included in off-line materials, these Terms
                will always control. By visiting, entering, or using the Websites and/or the services located at the Websites,
                (collectively, the "Services"), you indicate that you accept the current version of these Terms and that you
                agree to be bound by them. Acceptance of the Terms creates a binding contract ("Agreement") between you and
                Flourish Change Inc. that you will use the Websites and the Services only in a manner consistent with
                these Terms. If you do not agree to any of these Terms, then please do not access or use the Websites or
                Services.</p>
            <h6>I. Your Use of the Websites and Services</h6>
            <p>Flourish Change Inc. grants you a limited, non-transferable license to use the Websites in accordance with
                the Terms. You may only use the Websites to view information presented at the Websites and to make legitimate
                inquiries. You may not use the Websites for any other purposes, including without limitation, to make any false
                or fraudulent inquiries. The Websites and the content provided on the Websites, including any text, graphics,
                button icons, audio and video clips, digital downloads, data compilations and software (collectively,
                "Content"), may not be copied, reproduced, republished, framed, mirrored, uploaded, posted, transmitted,
                modified, sold, transferred, sub-licensed, distributed, disassembled, decompiled, or reverse engineered without
                the written permission of Flourish Change Inc., and/or its third party partners, except that you may
                download, display and print the materials presented on the Websites for your personal, non-commercial use only.</p>
            <p>In order to use the Service, you must create a service account ("Account") by registering on the site or the
                Domain to become a registered user of the Service (a "Registered User"). By creating an Account, you represent
                and warrant that: (a) all registration information for your Account (including, without limitation, your
                payment information) that you submit is truthful and accurate; and (b) you will maintain the accuracy of such
                information. When you register to establish an Account, you will also be asked to choose a login name and a
                password for your Account. You are entirely responsible for maintaining the confidentiality of your password.
                You agree not to use the Account, login name, or password of another User. You agree to notify Flourish Change Inc. immediately if you suspect any unauthorized use of your Account or access to your password.
                You are solely responsible for any and all use of your Account, including, without limitation, any fees that
                accrue for any use of your Account.</p>
            <p>All Users must comply with this Agreement.</p>
            <h6>II. Monitoring by Flourish Change Inc.</h6>
            <p>Your use of the products and services on the Websites may be monitored by Flourish Change Inc., and that
                the resultant information may be used by Flourish Change Inc. for its internal business purposes or in
                accordance with the rules of any applicable regulatory or selfregulatory organization.</p>
            <h6>III. Information Made Available Through this Service</h6>
            <p>You are permitted to store, display, analyze, modify, reformat, and print the information made available to you
                via these services only for your own use. You are not permitted to publish, transmit, or otherwise reproduce
                this information, in whole or in part, in any format to any third party without the express written consent of
                Flourish Change Inc. In addition, you are not permitted to alter, obscure, or remove any copyright,
                trademark or any other notices that are provided to you in connection with the information. Flourish Change
                Inc. reserves the right, at any time and from time to time, in the interests of its own editorial discretion
                and business judgment to add, modify or remove any of the information. These Terms and Conditions of Use are
                not intended to, and will not, transfer or grant any rights in or to the information other than those which are
                specifically described herein, and all rights not expressly granted herein are reserved by Flourish Change
                Inc. or the third party providers from whom Flourish Change Inc. has obtained the information. You are
                required to read and abide by any additional terms and conditions that may be posted on this service from time
                to time concerning information obtained from specific third party providers. Such third party providers shall
                have no liability to you for monetary damages on account of the information provided to you via this service.</p>
            <h6>IV. System Outages, Slowdowns, and Capacity Limitations/Delays in Order Routing, Executions, and Reports.</h6>
            <p>As a result of high Internet traffic, transmission problems, systems capacity limitations, and other problems,
                you may, at times, experience difficulty accessing the Website or communicating with Flourish Change Inc.
                through the Internet or other electronic and wireless services. Any computer system or other electronic device,
                whether, it is yours, an Internet service provider's or Flourish Change Inc.'s can experience
                unanticipated outages or slowdowns, or have capacity limitations.</p>
            <h6>V. Content not to be construed as a solicitation or recommendation</h6>
            <p>This material has been prepared for informational purposes only without regard to any particular user's
                objectives, financial situation, or means, and Flourish Change Inc. is not soliciting any action based
                upon it. This material is not to be construed as a recommendation; or an offer to buy or sell; or the
                solicitation of an offer to buy or sell any security, financial product, or instrument; or to participate in
                any particular risk strategy in any jurisdiction in which such an offer or solicitation, or risk strategy would
                be illegal. Certain transactions give rise to substantial risk and are not suitable for all companies. Although
                this material is based upon information that Flourish Change Inc. considers reliable and endeavors to keep
                current, Flourish Change Inc. has not verified this information and does not represent that this material
                is accurate, current, or complete and it should not be relied upon as such.</p>
            <h6>VI. Member Conduct Guidelines</h6>
            <p>(a) In consideration of your use of the Websites and Services, you represent that you are of legal age to form a
                binding contract and are not a person barred from receiving services under the laws of the United States or
                other applicable jurisdiction. You agree to provide true, accurate, current and complete information about
                yourself upon registration and thereafter. You agree that you are responsible for maintaining the
                confidentiality of any usernames, passwords, account, or any other tools or data that afford you access to
                restricted areas of the Websites or Services and are fully responsible for all activities that occur under your
                password or account. If you are sharing such usernames, passwords, etc. with others for the purpose of shared
                viewing of the Websites, you are also responsible for all users of such information, whether or not actually or
                expressly authorized by you. You agree to immediately notify Flourish Change Inc. of any unauthorized use
                of your password or account or any other breach of security. Flourish Change Inc. cannot and will not be
                liable for any loss or damage arising from your failure to comply with the Terms. You agree to comply with all
                local rules regarding online conduct and acceptable Content, including all applicable laws regarding the
                transmission of technical data exported from the United States or the country in which you reside.</p>
            <p>(b) You agree not to use the Websites or the Services to:</p>
            <p>1. upload, post, email, transmit or otherwise make available ("Post") any Content:</p>
            <ul>
                <li>that is unlawful, harmful, threatening, indecent, inflammatory, pornographic, profane, abusive, harassing,
                    tortuous, defamatory, vulgar, obscene, libelous, false or inaccurate, invasive of another's privacy,
                    hateful, or racially, ethnically or otherwise objectionable;</li>
                <li>that could constitute or encourage conduct that would be considered a criminal offense, give rise to civil
                    liability, or that would otherwise violate any law, or violate any contractual or fiduciary relationship
                    (such as inside information, proprietary and confidential information learned or disclosed as part of
                    employment relationships or under nondisclosure agreements);</li>
                <li>that infringes any patent, trademark, trade secret, copyright or other proprietary rights of any party;</li>
                <li>that contains software viruses or any other computer code, files or programs designed to interrupt, destroy
                    or limit the functionality of any computer software or hardware or telecommunications equipment virus, bug
                    or other harmful item; or</li>
                <li>that contains any unsolicited or unauthorized advertising, promotional materials, "junk mail," "spam,"
                    "chain letters," "pyramid schemes," or any other form of solicitation.</li>
            </ul>
            <p>2. Harm minors in any way;</p>
            <p>3. Impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person
                or entity;</p>
            <p>4. Violate any applicable local, state, national or international law, intentionally or unintentionally;</p>
            <p>5. "Stalk" or otherwise harass another;</p>
            <p>6. Collect or store personal data about other users;</p>
            <p>7. Transmit or otherwise transfer any Web pages, data or content found on the Websites to any other computer,
                server, Web site, or other medium for mass distribution or for use in any commercial enterprise; or</p>
            <p>8. Supply material support or resources to organizations designated by the United States government as a foreign
                terrorist organization pursuant to Section 219 of the Immigration and Nationality Act.</p>
            <p>While utilizing or accessing the Websites, the Services, or information or other Content available on the
                Websites, you also agree not to:</p>
            <p>1. Use any device, software or routine to interfere or attempt to interfere with the proper working of the
                Websites or interfere with or disrupt the Service or servers or networks connected to the Service, or disobey
                any requirements, procedures, policies or regulations of networks connected to the Service in any way;</p>
            <p>2. Use any "robot," "spider" or other automatic device, or a program, algorithm or methodology having similar
                processes or functionality, or any manual process, to monitor or copy any of the Web pages, data or content
                found on the Websites without the prior written permission of Flourish Change Inc.; or</p>
            <p>3. Imposes a burden or load on our infrastructure that Flourish Change Inc. deems in its sole discretion to
                be unreasonable or disproportionate to the benefits Flourish Change Inc. obtains from your use of the
                Websites.</p>
            <p>Content that violates these guidelines may be removed at the discretion of Flourish Change Inc. We have no
                obligation to remove content that you personally find objectionable or offensive, though we will investigate
                complaints referring to inappropriate use of the Websites.</p>
            <p>(c) You agree to be fully responsible for your own Content and the consequences of posting or publishing them.
                Under no circumstances will Flourish Change Inc. be liable in any way for any Content (other than for
                Content developed by us), including, but not limited to, for any errors or omissions in any Content, or for any
                loss or damage of any kind incurred as a result of the use of any Content posted, emailed or otherwise
                transmitted to or through the Websites. In the event that you have a dispute with a user of Flourish Change, Inc.
								and its Services, you release Flourish Change Inc. and its officers, members and employees from
                claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, suspected
                and unsuspected, disclosed and undisclosed, arising out of or in any way connected with such disputes.</p>
            <p>(d) In order to provide the Services, you hereby grant Flourish Change Inc. the royalty-free,
                non-exclusive, worldwide, and sublicensable right to use, modify, distribute, publish, copy, publicly display
                and publicly perform, in any format or medium now known or later developed, with or without attribution, any
                Content submitted by you on the Websites or Services, in connection with the Websites and Services, except as
                otherwise prohibited by the Privacy Policy.</p>
            <h6>VII. No determination of suitability has been made; not all risks are disclosed; private advisors should be
                consulted</h6>
            <p>The fact that Flourish Change Inc. has made the data and services provided on their Websites available to
                you constitutes neither a recommendation that you enter into a particular transaction nor a representation that
                any product described on their Websites are suitable or appropriate for you. Any discussion of the risks
                contained herein with respect to Flourish Change Inc.’s product should not be considered to be a
                disclosure of all risks or complete discussion of the risks which are mentioned. You should neither construe
                any of the material contained herein as business, financial, investment, legal, regulatory, tax, or accounting
                advice nor make this service the primary basis for any investment decisions made by or on behalf of you, your
                accountants, or your managed or fiduciary accounts, and you may want to consult your business advisor,
                attorney, and tax and accounting advisors concerning any contemplated transactions.</p>
            <h6>VIII. No representations made as to other sites or links</h6>
            <p>This service may provide links to certain Internet sites (the "Sites") sponsored and maintained by third
                parties. Flourish Change Inc. is providing such links solely as a convenience to you. Accordingly, Flourish Change, Inc.
								makes no representations concerning the content of the Sites. The fact that Flourish Change, Inc.
								has provided a link to the Site does not constitute an endorsement, authorization,
                sponsorship, or affiliation by Flourish Change Inc. with respect to the Site, its owners, or its
                providers. Flourish Change Inc. has not tested any information, software, or products found on any of the
                Sites and therefore does not make any representations with respect thereto, including any representations
                regarding the content or sponsors of the Site, or the suitability or appropriateness of the products or
                transactions described therein.</p>
            <h6>VIX. Intellectual Property Rights</h6>
            <p>Except for any Content submitted by users, the content on the Flourish Change Inc. Websites, including
                without limitation, the text, software, scripts, graphics, photos, sounds, music, videos, interactive features
                and the like, the trademarks, service marks and logos contained therein ("Marks"), and any other proprietary
                rights related thereto or appearing on the Websites are owned by or licensed to Flourish Change Inc.,
                subject to trademark, copyright and other intellectual property rights under United States and foreign laws and
                international conventions. Content on the Websites is provided to you AS IS for your information and personal
                use only and may not be used, copied, reproduced, reverse engineered, modified, distributed, transmitted,
                broadcasted, displayed, sold, licensed, or otherwise exploited for any other purposes whatsoever without the
                prior written consent of the respective owners. Flourish Change Inc. reserves all rights not expressly
                granted in and to the Websites and the Content. You agree to not engage in the use, copying, distributing or
                creating any derivative works based on any of the Content other than expressly permitted herein, including any
                use, copying, or distribution of content submitted by third parties obtained through the Websites for any
                commercial purposes. If you download or print a copy of the Content for personal use, you must retain all
                copyright and other proprietary notices contained therein. You agree not to rent, lease, loan, or sell access
                to the Service or provide the Service to any third party on a service bureau or time sharing basis or
                otherwise. You agree not to circumvent, disable or otherwise interfere with security related features of the
                Flourish Change Inc. Websites or features that prevent or restrict use or copying of any Content or
                enforce limitations on use of the Flourish Change Inc. Websites or the Content therein. You agree to be
                liable for any damages resulting from any infringement of copyright, trademark, or other proprietary right, or
                any other harm resulting from your use of the Websites.</p>
            <h6>X. Third Party Links</h6>
            <p>The Websites contain links to other websites that are provided solely as a convenience to you and not as an
                endorsement by Flourish Change Inc. of the contents or operation of such other websites. Flourish Change, Inc.
								shall not be responsible for the content of any other websites and makes no representation
                or warranty regarding any other websites or the contents or materials on such websites. If you decide to access
                other websites, you do so at your own risk. You agree not to hold Flourish Change Inc. responsible for the
                content or operation of such websites.</p>
            <h6>XI. Termination</h6>
            <p>Flourish Change Inc. may immediately change or terminate your access to the Services, the Websites, or any
                online membership(s) with Flourish Change Inc., with or without notice, at any time, without liability to
                you, any other user or any third party. Flourish Change Inc. reserves the right to terminate your online
                membership(s) if , without limitation, you have: (1) provided Flourish Change Inc. with false or
                misleading registration information; (2) interfered with other users or the administration of Flourish Change, Inc.
								Services or Websites; (3) upon a request by law enforcement or other governmental
                authorities; or (4) otherwise violated these Terms.</p>
            <h6>XII. Disclaimer of Warranties</h6>
            <p>ALL PRODUCTS, OFFERINGS, CONTENT, AND MATERIALS ON THE WEBSITE ARE PROVIDED "AS IS" AND WITHOUT WARRANTIES OF
                ANY KIND, EITHER EXPRESS OR IMPLIED. EXCEPT AS OTHERWISE REQUIRED BY LAW, Flourish Change Inc. DISCLAIMS
                ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
                MERCHANTABILITY, TITLE, NON-INFRINGEMENT AND FITNESS FOR A PARTICULAR PURPOSE. ADDITIONALLY, FLOURISH CHANGE INC.
								DOES NOT WARRANT THAT ACCESS TO THE WEBSITES WILL BE TIMELY, SECURE, AND/OR ERROR-FREE OR UNINTERRUPTED.
                Flourish Change Inc. DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS AS TO THE ACCURACY OR RELIABILITY OF ANY
                INFORMATION, RESULTS, OR CONTENT PROVIDED ON THE WEBSITES OR IN ANY EMAIL COMMUNICATION SENT BY OR ON BEHALF OF
                Flourish Change Inc..</p>
            <h6>XIII. Limitation of Liability</h6>
            <p>Flourish Change Inc. ASSUMES NO RESPONSIBILITY, AND SHALL NOT BE LIABLE FOR, ANY DAMAGES TO, OR VIRUSES
                THAT MAY INFECT YOUR COMPUTER EQUIPMENT OR OTHER PROPERTY ON ACCOUNT OF YOUR ACCESS TO, USE OF, OR BROWSING THE
                WEBSITES OR YOUR DOWNLOADING OF ANY MATERIALS, DATA, TEXT, IMAGES, VIDEO OR AUDIO FROM THE WEBSITES. IN NO
                EVENT SHALL Flourish Change Inc. BE LIABLE FOR ANY INJURY, LOSS, CLAIM, DAMAGE, OR ANY SPECIAL, EXEMPLARY,
                PUNITIVE, INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES OF ANY KIND (INCLUDING, BUT NOT LIMITED TO LOST PROFITS
                OR LOST SAVINGS), WHETHER BASED IN CONTRACT, TORT, STRICT LIABILITY, OR OTHERWISE, WHICH ARISES OUT OF OR IS IN
                ANY WAY CONNECTED WITH (I) ANY USE OF THIS SITE OR CONTENT FOUND HEREIN, (II) ANY FAILURE OR DELAY (INCLUDING,
                BUT NOT LIMITED TO THE USE OF OR INABILITY TO USE ANY COMPONENT OF THIS SITE), (III) OTHER MATTER RELATING TO
                THE WEBSITES AND/OR SERVICES.</p>
            <p>If, notwithstanding the foregoing, Flourish Change Inc. or any third party provider or distributor should
                be found liable for any loss or damage, which arises out of or is in any way connected with any of the above
                described functions or uses of the Websites, the Content, and/or the Services, the liability of Flourish Change, Inc.
								and the third party providers and distributors shall in no event exceed US$100.00. In its
                sole discretion, in addition to any other rights or remedies available to Flourish Change Inc. and without
                any liability whatsoever, Flourish Change Inc., at any time and without notice, may terminate or restrict
                your access to any component of the Websites or its Services. Some states do not allow certain limitations of
                liability, so the foregoing limitation may not apply to you.</p>
            <h6>XIV. Indemnity and Hold Harmless</h6>
            <p>You agree to indemnify, defend and hold harmless Flourish Change Inc., its affiliates and their respective
                officers, directors, shareholders, controlling persons, employees, agents, advisors, successors, transferees,
                participants and assigns from any and all third party claims, demands, liabilities, damages, losses, debt,
                charges, expenses, obligations, and/or costs (including, but not limited to, reasonable attorney’s fees and
                expenses) arising from: (i) your use and access to the Websites or of Flourish Change Inc. Services; (ii)
                your violation of the Terms; (iii) your violation of any third party right, including without limitation any
                trademark, copyright, property, proprietary, privacy, or other right or your infringement or infringement by
                any other user of your account, of any intellectual property or other right of any person or entity; (iv) any
                of your Content, including, without limitation, any Content posted or otherwise provided by you that infringes
                any copyright, trademark, intellectual property right of any person or defames any person or violates any
                person's rights of publicity or privacy, or otherwise violates these Terms; and (v) that may be incurred by or
                asserted against Flourish Change Inc. for work or services performed by them or its affiliates prior to
                the issuance of the policy. The Terms will inure to the benefit of successors, assigns, and licensees. This
                defense and indemnification obligation will survive these Terms and your use of the Websites and/or Services.</p>
            <h6>XV. Claims of Intellectual Property Infringement and Agent</h6>
            <p>If you believe your copyright, or other intellectual property rights have been infringed by the Websites,
                Content or Services, we ask that you please provide to Flourish Change Inc.’s Agent the following
                information required by the Online Copyright Infringement Liability Limitation Act of the Digital Millennium
                Copyright Act, 17 U.S.C. 512:</p>
            <ol>
                <li>A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive
                    right that is allegedly infringed;</li>
                <li> Identification of the copyright work claimed to have been infringed, or, if multiple copyrighted works at
                    a single online site are covered by a single notification, a representative list of such works at that
                    site;</li>
                <li>Identification of the material that is claimed to be infringing or to be the subject of infringing activity
                    and that is to be removed or access to which is to be disabled, and information reasonably sufficient to
                    permit us to locate the material;</li>
                <li>Information reasonably sufficient to permit us to contact the complaining party;</li>
                <li>A statement that the complaining party has a good-faith belief that use of the material in the manner
                    complained of is not authorized by the copyright owner, its agent, or the law; and</li>
                <li>A statement that the information in the notification is accurate, and under penalty of perjury, that the
                    complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly
                    infringed.</li>
            </ol>
            <p>Send such notice as directed above to:</p>
            <p>Flourish Change Inc.<br>Attn: Legal<br>6900 N. Dallas Parkway<br>Suite 200<br>Plano, TX 75024<br>Fax: +1
                972-665-5511<br>Email: hello@flourishchange.com</p>
            <h6>XVI. Governing Law</h6>
            <p>This Agreement and its performance shall be governed by the laws of the State of Texas, United States of
                America, without regard to its conflict of laws provisions. You consent and submit to the exclusive
                jurisdiction of the state and federal courts located in the State of Texas, United States of America, in all
                questions and controversies arising out of your use of the Websites, the Services, and/or the Terms. To the
                extent allowed by applicable law, any claim or cause of action arising from or relating to Flourish Change, Inc.
								must be brought within two (2) years from the date on which such claim or action arose or accrued.
								Flourish Change, Inc. controls and operates the Websites from its headquarters in the United States of America,
                and the Websites may not be appropriate or available for use in other locations. If you use the Websites
                outside the United States of America, you are responsible for following applicable local laws.</p>
            <h6>XVII. Arbitration and Damages </h6>
            <p>Any and all disputes, claims or controversies with Class Actions Services Holdings LLC, individually and/or dba
                Flourish Change Inc. and any affiliates including but not limited to: this Agreement or the breach,
                termination, enforcement, interpretation or validity thereof, including the determination of the scope or
                applicability of this agreement to arbitrate, shall be determined by arbitration in Collin County, Texas before
                one (1) arbitrator to be agreed to by the Parties. In the event that the Parties cannot agree within ten (10)
                days of filing for arbitration, then JAMS shall appoint an arbitrator. The arbitration shall be administered by
                JAMS pursuant to JAMS’ Streamlined Arbitration Rules and Procedures. The parties shall maintain the
                confidential nature of the arbitration proceeding and the award, including the hearing, except as may be
                necessary to prepare for or conduct the arbitration hearing on the merits, or except as may be necessary in
                connection with a court application for a preliminary remedy, a judicial challenge to an award or its
                enforcement, or unless otherwise required by law or judicial decision. Judgment on the award may be entered in
                any court having jurisdiction. In any arbitration arising out of this Agreement, the arbitrator is not
                empowered to award punitive, exemplary, incidental, consequential, indirect and/or loss profit damages and the
                parties waive any right to recover such damages, in any arbitration arising out of or related to this
                Agreement. This clause shall not preclude either Party from seeking provisional remedies in aid of arbitration
                as described in this Agreement.</p>
            <h6>XVIII. Class Action Waiver</h6>
            <p>ARBITRATION MUST BE ON AN INDIVIDUAL BASIS. THIS MEANS NEITHER YOU NOR Flourish Change Inc. MAY JOIN OR
                CONSOLIDATE CLAIMS IN ARBITRATION OR LITIGATE IN COURT OR ARBITRATE ANY CLAIMS AS A REPRESENTATIVE OR MEMBER OF
                A CLASS OR IN A PRIVATE ATTORNEY GENERAL CAPACITY.</p>
            <h6>XIX. Attorney’s Fees</h6>
            <p>If Flourish Change Inc. takes any action to enforce these Terms, such parties will be entitled to recover
                from you, and you agree to pay all reasonable and necessary attorney's fees and any cost of litigation, in
                addition to any other relief, at law or in equity, to which such parties may be entitled.</p>
            <h6>XX. Injunctive Relief</h6>
            <p>You acknowledge that a violation or attempted violation of any provision of these Terms will cause such damage
                to Flourish Change Inc. as will be irreparable, the exact amount of which would be difficult to ascertain
                and for which there will be no adequate remedy at law. Accordingly, you agree that Flourish Change Inc.
                shall be entitled as a matter of right to an injunction issued by any court of competent jurisdiction,
                restraining such violation or attempted violation of the Terms by you, or your affiliates, partners, or agents,
                as well as recover from you any and all costs and expenses sustained or incurred by Flourish Change Inc.
                in obtaining such an injunction, including, without limitation, reasonable attorneys’ fees. You agree that no
                bond or other security shall be required in connection with such injunction.</p>
            <h6>XXI. Assignment</h6>
            <p>You may not assign, convey, subcontract or delegate your rights, duties or obligations hereunder.</p>
            <h6>XXII. Relationship</h6>
            <p>The relationship between Flourish Change Inc. and you will be that of independent contractors, and neither
                of us nor any of our respective officers, agents or employees will be held or construed to be partners, joint
                ventures, fiduciaries, employees or agents of the other.</p>
            <h6>XXIII. Modification</h6>
            <p>Flourish Change Inc. may make changes to the information and/or materials offered on or available from the
                Websites at any time without notice, and Flourish Change Inc. may change these Terms at any time without
                notice by posting updated terms of use on the Websites. Your continued use of the Websites after such changes
                have been posted signifies your assent and agreement to the new Terms, even if you have not reviewed the
                changes. Therefore, you should check the Terms and Conditions posted on the Websites periodically for updates
                and changes.</p>
						<h6>XXIV. Fees</h6>
							</p>Flourish Change, Inc. will make reasonable efforts to deliver donations to designated organization partners. If the organization is not a client, as designated by a badge, then Flourish Change, Inc. will exact a processing and handling fee on the donation from you. This fee can vary, without notice to you, based on market conditions and size of donation. If an organization is a client of Flourish Change, Inc.,
							then no fees will be enacted on you; however, the organization may pay servicing fees as defined in the agreement between Flourish Change, Inc. and the organization.</p>
            <h6>XXV. Refund Policy</h6>
            <p>No refunds will be given after the donation is processed in accordance with donation law. If you have any further questions or a dispute, please contact support@flourishchange.com and we will make reasonable efforts to resolve the situation.</p>
            <h6>XXVI. Additional Terms</h6>
            <p>Additional terms and conditions may apply to purchases of goods and services and other uses of portions of the
                Websites, and you agree to abide by such other terms and conditions.</p>
						<h6>XXVII. Email and SMS Marketing</h6>
									</p>By providing your email and phone number, you permit us to send email communication at the sole digression of the Company. If at any time, you wish to opt-out of these communications, you may by following the unsubscribe instructions provided in the messages in accordance with FCC law.</p>
            <h6>XXVIII. Severability</h6>
            <p>This Agreement shall be deemed severable. In the event that any provision is determined to be unenforceable or
                invalid, such provision shall nonetheless be enforced to the fullest extent permitted by applicable law, and
                such determination shall not affect the validity and enforceability of any other remaining provisions.</p>
						<h6>XXIX. Headings</h6>
            <p>The headings used in this Agreement are included for convenience only and will not limit or otherwise affect the
                terms and conditions herein.</p>
            <h6>XXX. Entire Agreement</h6>
            <p>This Agreement, together with any terms and conditions incorporated herein or referred to herein, constitute the
                entire agreement between us relating to the subject matter hereof, and supersedes any prior understandings or
                agreements (whether oral or written) regarding the subject matter, and may not be amended or modified except in
                writing or by making such amendments or modifications available on the Websites.</p>
            <p>If you are aware of any violations of these Terms and Conditions, please report them.</p>
        `,
	}
}
