import React from 'react'
import PropTypes from 'prop-types'
import MediaQuery from 'react-responsive'

import theme from '../../../theme/theme'

import {
	Row,
} from '../../layouts'
import { Paragraph, Title } from '../'

class Testimonial extends React.Component {
	render() {
		const { data } = this.props
		return (
			<div>
				<Row style={{display: 'flex'}}>
					<Title color="white" fontSize={'50px'} style={{marginTop: -25}}>{'"'}</Title>
					<MediaQuery minWidth={theme.breakPoints.small}>
						<Paragraph height={'200px'} color="white">{data.text}</Paragraph>
					</MediaQuery>
					<MediaQuery maxWidth={theme.breakPoints.small}>
						<Paragraph color="white">{data.text}</Paragraph>
					</MediaQuery>

				</Row>
				<Paragraph marginBottom={'0px'} textAlign="right" color="white">-{data.author}</Paragraph>
				<Paragraph marginTop={'0px'} textAlign="right" color="white" dangerouslySetInnerHTML={{ __html: data.title}} />
			</div>
		)
	}
}

Testimonial.propTypes = {
	data: PropTypes.object
}

export default Testimonial
