import React from 'react'
import PropTypes from 'prop-types'

import {
	Title,
	List,
	Item,
	Anchor,
} from './footerMenuStyles'

const FooterMenu = (props) => {
	return (
		<div>
			<Title>{props.data.title}</Title>
			<List>
				{props.data.menu && props.data.menu.map((item, i) => {
					return (
						<Item key={i}>
							<Anchor href={item.link}>{item.text}</Anchor>
						</Item>
					)
				})}
			</List>
		</div>
	)
}

FooterMenu.propTypes = {
	data: PropTypes.object
};

export default FooterMenu