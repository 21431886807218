import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { CardElement, injectStripe } from 'react-stripe-elements'
import MediaQuery from 'react-responsive'

import { InputStyle as InputContainer } from '../InputField/inputFieldStyles'

import {
	Container,
	Row,
	Column,
} from '../../layouts'

import {
	Title,
	Button,
	BtnField,
	LoadingData,
	Paragraph,
	Input,
	CheckBox,
	InputField
} from '../'

import {
	charge,
	setDonateNow
} from '../../../factory'

import theme from '../../../theme/theme'

const {
	oneTimeDonation,
} = theme.sections

// TODO: Specific error for page
class CheckoutForm extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			loading: false,
			error: '',
			coupon: '',
			phone: '',
			email: '',
			firstname: '',
			lastname: '',
			selected: 5
		}

		this.onChange = this.onChange.bind(this)
		this.onSuccess = this.onSuccess.bind(this)
		this.onError = this.onError.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
		this.validation = this.validation.bind(this)
		this.selectDonateAmount = this.selectDonateAmount.bind(this)
		this.setCustomDonateAmount = this.setCustomDonateAmount.bind(this)
	}

	onSuccess() {
		try {
			/* eslint-disable-next-line */
			analytics.track(`Checkout Form Success`)
		} catch (e) {
			// ignore
		}

		const state = Object.assign({}, this.state)
		delete state.price
		delete state.position
		delete state.loading
		delete state.error
		this.setState({ loading: false })
		var queryString = Object.keys(state).map(key => key + '=' + state[key]).join('&')

		window.location = '/welcome?' + queryString
	}

	onError() {
		try {
			/* eslint-disable-next-line */
			analytics.track(`Checkout Form Error`)
		} catch (err) {
			// ignore
		}

		this.setState({ error: "There was an issue processing your card. Please try again.", loading: false })
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value })
	}

	handleSubmit(ev) {
		// We don't want to let default form submission happen here, which would refresh the page.
		ev.preventDefault()

		this.setState({ error: '' })
		if (!this.validation() ) {
			return
		}

		const { firstname, lastname, email } = this.state

		try {
			/* eslint-disable-next-line */
			analytics.identify({
				firstName: firstname,
				lastName: lastname,
				email,
			})
		} catch (e) {
			// ignore
		}

		try {
			/* eslint-disable-next-line */
			analytics.track(`One Time Donation Submitted`)
		} catch (e) {
			// ignore
		}

		const data = Object.assign({}, this.state)
		if (this.state.customActive) {
			data.selected = this.state.customValue
		}

		// Within the context of `Elements`, this call to createToken knows which Element to
		// tokenize, since there's only one in this group.
		this.setState({ loading: true, error: '' })
		this.props.stripe.createToken({ name: `${firstname} ${lastname}` })
			.then(({ token }) => {
				this.props.submit({data, stripeToken: token, onErrorCallback: ()=>this.onError()})
			})
			.catch(() => this.setState({error: 'There was an issue with your card. Please try again.', loading: false}))
	}

	selectDonateAmount(e) {
		if (Number(e.target.value)) {
			const value = Number(e.target.value)

			if (value > 0 || value === -1) {
				this.setState({
					customActive: value === -1 ? true : false,
					selected: value,
				})
			}
		}
	}

	setCustomDonateAmount(e) {
		if (Number(e.target.value)) {
			const value = Number(e.target.value)
			if (value > 0 || value === -1) {
				this.setState({
					customValue: value,
				})
			}
		}
	}

	validation() {
		const { firstname, lastname, email } = this.state
		const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line

		if (!firstname || !lastname) {
			this.setState({
				error: 'Please enter your first and last name.',
			})
			return false
		} else if (!email || !emailRegex.test(email)) {
			this.setState({
				error: 'Please enter a valid email.',
			})

			return false
		}

		this.setState({
			error: '',
		})

		return true
	}

	renderButtons() {
		const { prevPosition } = this.props

		return(
			<div>
				<MediaQuery minDeviceWidth={1000}>
					<div width="100%" style={{marginTop: '1em', justifyContent: 'center', display: 'flex', alignItems: 'center', flexDirection: 'row'}}>
						<Column maxWidth={'50%'} style={{ margin: 'auto', justifyContent: 'center' }}>
							<Button bgColor={theme.colors.crusta} fontColor={theme.colors.white} marginRight={'0.5em'} maxWidth={'110px'} onClick={prevPosition}>{'Back'}</Button>
						</Column>
						<Column  maxWidth={'50%'} style={{ margin: 'auto', justifyContent: 'center' }}>
							<BtnField onClick={this.handleSubmit} marginLeft={'auto'} marginRight={'auto'} maxWidth={'150px'} hoverBg={theme.colors.salomie}>Submit</BtnField>
						</Column>
					</div>
				</MediaQuery>
				<MediaQuery maxDeviceWidth={1000} styly={{width: '100%'}}>
					<Column style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} align={'center'} justify={'center'} width="100%" style={{marginTop: '1em', width: 200}}>
						<BtnField onClick={this.handleSubmit} marginLeft={'auto'} marginRight={'auto'} style={{marginBottom: 15, marginTop: 15, width: '100%'}} hoverBg={theme.colors.salomie}>Submit</BtnField>
						<Button bgColor={theme.colors.crusta} fontColor={theme.colors.white} marginTop={'1em'} marginLeft={'auto'} marginRight={'auto'} width={'125px'} onClick={prevPosition} style={{marginBottom: '2em'}}>{'Back'}</Button>
					</Column>
				</MediaQuery>
			</div>
		)
	}

	render() {
		const { loading, error, firstname, lastname, email } = this.state

		return (
			<div maxWidth={'840px'}>
				<div style={{ borderRadius: 8 }}>
					<Row style={{ marginTop: 15 }}>
						<Column>
							<Title fontSize={'33px'} textAlign={'center'} marginBottom={'0.5em'} >How much would you like to give?</Title>
						</Column>
					</Row>
					<Row align={'center'} style={{ marginRight: 0, marginLeft: 0  }}>
						<Column maxWidth={'50%'}>
							<Container>
								<MediaQuery minDeviceWidth={1000}>
									<Row marginBottom={'0'} width={"100%"} style={{flexWrap: 'wrap', paddingLeft: "5%"}}>
										{oneTimeDonation.forms.values.map((item, i) =>
											(<div style={{width: '50%'}}>
												<CheckBox name={'donation'} key={i} label={item.name} defaultSelected={item.value === this.state.selected} value={item.value} onChange={this.selectDonateAmount} />
											</div>)
										)}
									</Row>
								</MediaQuery>
								<MediaQuery maxDeviceWidth={1000}>
									<Row marginBottom={'0'} width={"100%"} style={{flexWrap: 'row wrap', paddingLeft: "0%", alignContent: 'flex-start' }}>
										{oneTimeDonation.forms.values.map((item, i) => (
											<Column style={{width: '100%'}}>
												<CheckBox style={{marginBottom: 0}} name={'donation'} key={i} label={item.name} defaultSelected={item.value === this.state.selected} value={item.value} onChange={this.selectDonateAmount} />
											</Column>
										)
										)}
									</Row>
								</MediaQuery>
								{this.state.customActive &&
									<Row>
										<Column maxWidth={'300px'}>
											<InputField disabled={this.state.customActive} label="Amount $" type="number" onChange={this.setCustomDonateAmount} min="0" />
										</Column>
									</Row>
								}
							</Container>
						</Column>
						<Column maxWidth={'50%'}>
							<Row>
								<Column lateralPaddings={'10px'} maxWidth={'100%'}>
									<Paragraph style={{ marginBottom: 0 }}>First Name</Paragraph>
									<Input value={firstname} name="firstname" type="text" onChange={this.onChange}  minLength="4" shadow autoComplete="off" required="required" />
								</Column>
							</Row>
							<Row>
								<Column lateralPaddings={'10px'} maxWidth={'100%'}>
									<Paragraph style={{ marginBottom: 0 }}>Last Name</Paragraph>
									<Input value={lastname} name="lastname" type="text" onChange={this.onChange}  minLength="4" shadow autoComplete="off" required="required" />
								</Column>
							</Row>
							<Row>
								<Column lateralPaddings={'10px'} maxWidth={'100%'}>
									<Paragraph style={{ marginBottom: 0 }}>Email</Paragraph>
									<Input value={email} name="email" type="text" onChange={this.onChange}  minLength="4" shadow autoComplete="off" required="required" />
								</Column>
							</Row>
							<Row>
								<Column lateralPaddings={'10px'} maxWidth={'100%'}>
									<Paragraph style={{ marginBottom: 0 }}>Payment Information</Paragraph>
									<InputContainer shadow style={{ padding: '0.8em 0.8em' }}>
										<CardElement/>
									</InputContainer>
								</Column>
							</Row>
						</Column>
					</Row>
					<Container>
						{error &&
						<Row style={{ textAlign: 'center', margin: 0, width: '100%'}}>
							<Paragraph style={{ margin: 'auto', color: theme.colors.crusta, fontSize: 16, textAlign: 'center', marginBottm: 0 }}>{error}</Paragraph>
						</Row>
						}
						<Row align={'center'} width={"100%"} style={{justifyContent: 'center', alignItems: 'center', }}>
							{loading ?
								<Column maxWidth={'100%'} style={{ margin: '10px 0px' }}>
									<LoadingData/>
								</Column>
								:
								this.renderButtons()
							}
						</Row>
						<Row style={{ textAlign: 'center', width: '90%', marginTop: '2em', margin: 'auto' }}>
							<Paragraph style={{ color: '#4a4a4a', fontSize: 12 }}>By pressing "Submit", I authorize Flourish Change Inc. to charge the card provided and send the donation to the organization you have indicated. Your project selection is a request, not a formal designation.
							</Paragraph>
						</Row>
					</Container>
				</div>
			</div>
		)
	}
}

CheckoutForm.propTypes = {
	error: PropTypes.any,
	onChange: PropTypes.func,
	prevPosition: PropTypes.func,
	clearErrors: PropTypes.func,
	submitOrgRequest: PropTypes.func,
	loading: PropTypes.bool,
	success: PropTypes.bool,
	stripe: PropTypes.object,
	charge: PropTypes.func,
	clearCoupon: PropTypes.func,
	values: PropTypes.object,
	plan: PropTypes.string,
	submit: PropTypes.func
}

const mapStateToProps = (state) => {
	const { utilState } = state
	return {
		error: utilState.error,
		pending: utilState.pending,
		success: utilState.success,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		charge,
		onDonation: (params, stripe, callBack) => dispatch(setDonateNow(params, stripe, callBack)),
	}
}

export default injectStripe(connect(
	mapStateToProps,
	mapDispatchToProps
)(CheckoutForm))
