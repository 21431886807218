import React from 'react'
import PropTypes from 'prop-types'

import {
	Container,
	Row,
	Column,
} from '../../layouts'

import {
	Title,
	Paragraph,
	Image,
	Button,
} from '../../ui'

import {
	MsjContainer,
} from './thankYouMsjStyles'

import theme from '../../../theme/theme'

const ThankYouMsj = (props) => {
	return (
		<MsjContainer>
			<Container>
				<Row>
					<Column>
						<Title fontSize={'33px'} marginLeft={'0'}>{props.title}</Title>
						<Paragraph fontSize={'21px'} maxWidth={'552px'}>
							{props.description1 + (props.selectedProj ? props.selectedProj.name + ' project' : (props.selectedOrg ? props.selectedOrg.title + ' charty' : ''))+ '. ' + props.description2}
						</Paragraph>
						<Image src={props.image} marginLeft={'auto'} marginRight={'auto'} maxWidth={'160px'} maxHeight="250px" />
						<Paragraph fontSize={'21px'}>{props.note}</Paragraph>
						<Row>
							<Column vAlign={'center'} row>
								<Button marginLeft={'5px'} href={props.redirect} maxWidth={'180px'}>{props.homeBtn.text}</Button>
							</Column>
						</Row>
					</Column>
				</Row>
			</Container>
		</MsjContainer>
	)
}

ThankYouMsj.propTypes = {
	title: PropTypes.string,
	description1: PropTypes.string,
	description2: PropTypes.string,
	image: PropTypes.string,
	note: PropTypes.string,
	giveBtn: PropTypes.object,
	homeBtn: PropTypes.object,
	backToStart: PropTypes.func,
	selectedOrg: PropTypes.object,
	selectedProj: PropTypes.object,
	redirect: PropTypes.string
}

export default ThankYouMsj
