import React from 'react'
import PropTypes from 'prop-types'

import {
	Title,
	Label,
	Text,
} from './footerBlockStyles'



const FooterBlock = (props) => {
	return (
		<div>
			<Title>{props.data.title}</Title>
			<Label>{props.data.email.label}</Label>
			<Text  dangerouslySetInnerHTML={{ __html: props.data.email.text}} />
			<Label>{props.data.phone.label}</Label>
			<Text  dangerouslySetInnerHTML={{ __html: props.data.phone.text}} />
			<Label>{props.data.address.label}</Label>
			<Text  dangerouslySetInnerHTML={{ __html: props.data.address.text}} />
		</div>
	)
}

FooterBlock.propTypes = {
	data: PropTypes.object
};

export default FooterBlock