// Helper method to pull errors, to be used in all the actions
const showError = (e) => {
	if (e.response && e.response.data && e.response.data.validation_errors) {
		const errors = e.response.data.validation_errors
		return errors[Object.keys(errors)[0]][0]
	} 
		return e
	
}

export default showError
