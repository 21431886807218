import React from 'react'
import MediaQuery from 'react-responsive'

import { scrollTo } from '../../../util'

import {
	Bar,
	BarContainer,
	Logo,
	Menu,
	MenuOption,
	MenuLink,
} from './topBarStyles'

import {
	Container,
} from '../../layouts'

import theme from '../../../theme/theme'

class TopBar extends React.Component {
	constructor(props) {
		super(props)

		this.linking = this.linking.bind(this)
	}

	linking(e) {
		e.preventDefault()

		const link = e.target.getAttribute('href')

		if (link.indexOf('#') !== -1) {
			if (window.location.pathname === '/') {
				const element = document.getElementById(link.substring(2))
				scrollTo(element)
			} else {
				window.location.href = link
			}
		} else {
			window.location.href = link
		}
	}

	render() {
		return (
			<Bar>
				<Container>
					<BarContainer>
						<Logo />
						{
							// process.env.STAGING && process.env.STAGING.includes('true') && <h1> STAGING </h1>
						}
						<MediaQuery minWidth={theme.breakPoints.small}>
							<Menu>
								{(theme.mainMenu && theme.mainMenu.length) && theme.mainMenu.map((option, i) =>
									(<MenuOption key={i} highlight={option.highlight}>
										<MenuLink onClick={this.linking} href={option.link}>{option.name}</MenuLink>
									</MenuOption>)
								)}
							</Menu>
						</MediaQuery>
					</BarContainer>
				</Container>
			</Bar>
		)
	}
}

export default TopBar
