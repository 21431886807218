import colors from '../colors'

export default {
	bg: {
		color: colors.porcelain,
		image: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJYAAAAOCAYAAAG6M9VaAAAABGdBTUEAALGPC/xhBQAAAPFJREFUaAXtl7ENAjEMRY9biQkYgR2oGIWKkqEoaanYgfaQm1Mwyg/RTwHSS5XE+bHz7Dsl0zSwbZbrdin325+f5XDaHS9v48P9sY7n22ntD+/IyFRUEUmO7GOzHG4+dmnPzkpb9Esk2ZYDyXbGPQRkGlUKw4lKo0phaFUa51hA+4KATF9L30qv0qvUK13YWqWh9KpslC5slFWLUGEHVgGD7kAC/LM6YPIZAquDQMdSKgtYHQRYCgEI/BEB65rlnNN5Vjp+nSep49d5zjp+naew5dcRo4VAjQA30hoZ5i0CFJaFD3GNAIVVI8M8BCDwewRe8FVDW/64NdwAAAAASUVORK5CYII=",
		repeat: 'repeat-x',
		position: 'center 101%',
	},
	content: {
		footerLogo: 'https://flourishfiles.blob.core.windows.net/files/Marketing/LogoPNGs/RGB/_Primary/Lockup%402x.png',
		footerDescription: "<strong>Flourish began with a simple question:</strong><br /> How can people of all ages — particularly the younger generation — give to and engage with the organizations, and causes, they care about?",
		navigation: {
			title: "Navigation",
			menu: [
				{
					text: "Home",
					link: "/#intro",
				},
				// {
				// 	text: "Features",
				// 	link: "/#about",
				// },
				{
					text: "Non-profits",
					link: "/nonprofits",
				},
				// {
				// 	text: 'Pricing',
				// 	link: '/pricing'
				// },
				{
					text: "Donate Now",
					link: "/donate",
				},
				{
					text: "Security",
					link: "/security",
				},
				{
					text: "Blog",
					link: "/blog",
				},
			]
		},
		legal: {
			title: "Legal",
			menu: [
				{
					text: "Privacy Policy",
					link: "/privacy#top",
				},
				{
					text: "Terms of Use",
					link: "/terms-and-conditions#top",
				},
			],
		},
		contact: {
			title: "Contact Us",
			email: {
				label: "Email:",
				text: "<a href=\"mailto:hello@flourishchange.com\" title=\"hello@flourishchange.com\">hello@flourishchange.com</a>",
			},
			phone: {
				label: "Phone:",
				text: "<a href=\"tel:+1-512-943-7828\" title=\"+1 (512)943-7828\">+1 (512)943-7828</a>",
			},
			address: {
				label: "Headquarters:",
				text: "6900 N. Dallas Pkwy<br /> Dallas, TX 75024",
			},
		},
		social: {
			title: "Feeling Social?",
			twitter: "https://twitter.com/FlourishChange",
			facebook: "https://www.facebook.com/theflourishchange/?ref=flourishchange.com",
			instagram: "https://www.instagram.com/flourishchange/?hl=en",
			appstore: {
				image: "https://flourishfiles.blob.core.windows.net/files/Marketing/AppBadges/app-store.svg",
				link: "https://itunes.apple.com/us/app/flourish-donations/id1356801492?mt=8",
			},
			googlePlay: {
				image: 'https://flourishfiles.blob.core.windows.net/files/Marketing/AppBadges/google-play.png',
				link: 'https://play.google.com/store/apps/details?id=com.flourish.flourish&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
			},
		},
		copyright: "© 2018 Copyright <strong>Flourish</strong>. All Rights Reserved.<br /> Made with <strong>Love</strong> by Flourish Change, Inc. in Austin, TX",
	},
}
