import axios from 'axios'

import {
	ADD_ORG_REQUEST,
	ADD_ORG_SUCCESS,
	ADD_ORG_FAIL,
	GET_CATEGORIES_REQUEST,
	GET_CATEGORIES_SUCCESS,
	GET_CATEGORIES_FAIL,
	SEND_SLACK_REQUEST,
	SEND_CHECK_SUCCESS,
	SEND_SLACK_ERROR,
	COUPON_SUCCESS,
	COUPON_FAIL,
	CLEAR_COUPON,
	ADMINAPI,
	API,
	STAGING,
	COUPON_REQUEST
} from '../theme/actionTypes'


export const submitOrgRequest = (data, callBack) => (dispatch) => {
	dispatch({ type: ADD_ORG_REQUEST })

	axios.post(`${ADMINAPI}/welcome/new/`, data, { headers: { 'Content-Type': 'application/json' } })
		.then(() => {
			dispatch({ type: ADD_ORG_SUCCESS }); callBack()
		})
		.catch((e) => {
			console.log(e); dispatch({ type: ADD_ORG_FAIL })
		})
}

export const getCategories = () => (dispatch) => {
	dispatch({ type: GET_CATEGORIES_REQUEST })
	axios.get(`${API}/v1/organization_category/list`, { headers: { 'Content-Type': 'application/json', 'flourish-test-header': STAGING() } })
		.then((res) => {
			dispatch({ type: GET_CATEGORIES_SUCCESS, payload: res.data.data })
		})
		.catch(() => {
			dispatch({ type: GET_CATEGORIES_FAIL })
		})
}

export const charge = ({ token, plan, coupon, metadata, callBack, callBackError }) => {
	axios.post(`${ADMINAPI}/payments/charge/`, { token, plan, metadata, coupon })
		.then(() => {
			callBack()
		})
		.catch((e) => {
			try {
				callBackError(e.response.data)
			} catch (TypeError) {
				callBackError('Unable to process payment. Please try again.')
			}
		})
}

export const requestCheck  = ({fullname, email, plans, bill, discount, coupon, callBack}) => (dispatch) => {
	dispatch({type: SEND_SLACK_REQUEST})
 	var text = `${fullname}, ${email}, is paying by check for ${plans}. Their total is $${bill}.`
	if (discount !== 0) {
		 text = text + ` They used the code ${coupon} to get ${discount} off`
	}

	sendSlack(text)
		.then(()=>{
			dispatch({type: SEND_CHECK_SUCCESS})
			callBack()
		})
		.catch({type: SEND_SLACK_ERROR})
}

export const requestCall = ({organization, phone, pledgeCount, totalPledges}) => (dispatch) => {
	dispatch({type: SEND_SLACK_REQUEST})
 	var text = `${organization}, ${phone}, requested a call to finance ${totalPledges} from ${pledgeCount} pledges`

	sendSlack(text)
		.then(()=>{
			dispatch({type: SEND_CHECK_SUCCESS})
		})
		.catch({type: SEND_SLACK_ERROR})
}

export const newOrgSignUp = (text) => (dispatch) => {
	dispatch({type: SEND_SLACK_REQUEST})
	sendSlack(text)
		.then(()=>{
			dispatch({type: SEND_CHECK_SUCCESS})
		})
		.catch({type: SEND_SLACK_ERROR})
}

export const sendSlack = (text) => {
	return axios.post(`${process.env.slack_webhook}`, `{"text":"${text}"}`,
		{headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}} )
}

export const getCoupon = (coupon) => (dispatch) => {
	dispatch({type: CLEAR_COUPON})
	dispatch({type: COUPON_REQUEST})

	axios.post(`${ADMINAPI}/payments/validateCoupon/`, { coupon })
		.then((res) => {
			dispatch({ type: COUPON_SUCCESS, payload: res.data })
		})
		.catch((e) => {
			let message = "Coupon Invalid"
			try {
				message = e.response.data
			} catch (_) {
				// ignore
			}
			dispatch({ type: COUPON_FAIL, payload: message })
		})
}

export const newDealAlert =  ({fullname, email, plans, bill, discount, coupon}) => (dispatch) => {
	dispatch({type: SEND_SLACK_REQUEST})
 	var text = `${fullname}, ${email}, just paid you for ${plans}. Their total is $${bill}.`
	if (discount !== 0) {
		 text = text + ` They used the code ${coupon} to get ${discount} off`
	}

	sendSlack(text)
		.then(()=>{
			dispatch({type: SEND_CHECK_SUCCESS})
		})
		.catch({type: SEND_SLACK_ERROR})
}


export const clearCoupon = () => (dispatch) => {
	dispatch({ type: CLEAR_COUPON })
}
