export default {
	content: {
		title: "Donations That Fit You",
		features: [
			{
				icon: 'https://flourishfiles.blob.core.windows.net/files/Marketing/Icons/recurring_donations.png',
				title: "Recurring Donations",
				paragraph: "Set up automatic donations on a daily basis — as little as 25 cents.",
			},
			{
				icon: 'https://flourishfiles.blob.core.windows.net/files/Marketing/Icons/round_up_icon.png',
				title: "Round Up",
				paragraph: "$3.50 for your coffee. $0.50 for hurricane relief.",
			},
			{
				icon: 'https://flourishfiles.blob.core.windows.net/files/Marketing/Icons/one_time_project.png',
				title: "One-Time Donations",
				paragraph: "Feeling charitable? Give a little extra for a project you care about.",
			},
			{
				betaLabel: 'https://flourishfiles.blob.core.windows.net/files/Marketing/Icons/Beta_tag.png',
				icon: 'https://flourishfiles.blob.core.windows.net/files/Marketing/Icons/double_dip.png',
				title: "Double Dip Rules",
				paragraph: "Set donations for everytime you treat yourself. For example: “Give $2 every time I buy ice cream.”",
			},
		]
	}
}
