import styled, { keyframes } from 'styled-components'

import theme from '../../../theme/theme'

export const rotate = keyframes`
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(360deg);
  }
`

export const Overlay = styled.div`
    background-color: ${theme.colors.white};
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    padding: ${props => props.padding ? props.padding : '1em 0'};
    text-align: ${props => props.align ? props.align : 'center'};
    width: 100%;
`

export const Image = styled.img.attrs({
	src: theme.images.isotype,
})`
    animation: ${rotate} 3.5s infinite linear;
    margin-bottom: 0;
    max-width: ${props => props.maxWidth ? props.maxWidth : '96px'};
`