import styled from 'styled-components'

import theme from '../../../theme/theme'

export const BoxContainer = styled.div`
    align-content: center;
    background-color: ${props => props.bgColor ? props.bgColor : theme.colors.white};
    border: 2px solid ${props => props.borderColor ? props.borderColor : 'transparent'};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: ${props => props.height ? props.height : '250px'};
    margin-left: ${props => props.marginLeft ? props.marginLeft : 'auto'};
    margin-right: ${props => props.marginRight ? props.marginRight : 'auto'};
    max-width: 445px;
    padding: 2em;
    width: 100%;

    :hover {
      background-color: ${props => props.hoverColor ? props.hoverColor : props.bgColor};
      border-color: ${props => props.hoverColor ? props.hoverColor : props.borderColor};
      cursor: ${props => props.onClick && 'pointer'};
    }

    @media (max-width: ${theme.breakPoints.small}) {
        height: 100vh;
        max-height: 650px;
    }

    @media (max-width: ${theme.breakPoints.xsmall}) {
        height: 100%;
        max-height: 100%;
        margin-bottom: 1em;
    }
`
