import React from 'react'
import MediaQuery from 'react-responsive'

import { scrollTo } from '../../../util'

import {
	BannerContainer,
} from './BannerStyles'

import {
	Paragraph,
	Title
} from '../'

import {
	Container,
	Row,
	Column,
	Section
} from '../../layouts'

import theme from '../../../theme/theme'

class Banner extends React.Component {
	constructor (props) {
		super(props)

		this.linking = this.linking.bind(this)
	}

	linking (e) {
		e.preventDefault()

		const link = e.target.getAttribute('href')

		if (link.indexOf('#') !== -1) {
			if (window.location.pathname == '/') {
				const element = document.getElementById(link.substring(2))
				scrollTo(element)
			} else {
				window.location.href = link
			}
		} else {
			window.location.href = link
		}
	}

	render () {
		return (
			<Section bg={theme.sections.forwardThinking.bg}>
				<Container>
					<Row style={{ marginBottom: 0 }}>
						<Column center style={{ alignItems: 'center', paddingTop: '1em' }}>
							<Title fontSize="36px" color="white">Attending AFP 2019?</Title>
							<Title fontSize="36px" color="white"> Come by booth 202 to get 15% off your first year</Title>
						</Column>
					</Row>
					<Row center style={{ marginTop: 0, paddingTop: 0 }}>
						<Column center style={{ alignItems: 'center' }}>
							<Paragraph fontSize="12px" color="white">15% offer valid with AFP ticket reciept before 4/16/2019</Paragraph>
						</Column>
					</Row>
				</Container>
			</Section>
		)
	}
}

export default Banner
