import styled from 'styled-components'

import theme from '../../../theme/theme'

export const BannerContainer = styled.div`
    background-color: ${theme.colors.wewak};
    width: 100%;
    alignItems: 'center';
    justifyContent: 'center';
`
