import axios from 'axios'

import {
	GET_ORGS_REQUEST,
	GET_ORGS_SUCCESS,
	GET_ORGS_FAILURE,
	GET_PROJECTS_SUCCESS,
	GET_PROJECTS_REQUEST,
	SELECT_ORG,
	SELECT_PROJ,
	SELECT_PROJECTS,
	CLEAR_ORG_AND_PROJ,
	CLEAR_PROJECTS,
	CLEAR_SELECTED_PROJ,
	CLEAR_DONATE_NOW,
	CLEAR_ERRORS,
	DONATE_NOW_SUCCESS,
	DONATE_NOW_REQUEST,
	DONATE_NOW_FAILURE,
	ONE_TIME_DONATION_AUTHED_FAILURE,
	ONE_TIME_DONATION_AUTHED_SUCCESS,
	ONE_TIME_DONATION_AUTHED_REQUEST,
	MESSAGE,
	API,
	TEST_HEADER,
} from '../theme/actionTypes'

import showError from '../util/showError'

/* ------------------------- Get functions ---------------------------*/

// Action to get projects for a given organization
export const getOrgs = () => (dispatch) => {
	dispatch({type: GET_ORGS_REQUEST})

	axios.get(`${API}/v1/organization/list_names`, { headers: { 'Content-Type': 'application/json', 'flourish-test-header': TEST_HEADER } })
		.then(res => {
			const organizations = res.data.data.length > 0 ? res.data.data.filter(org => org.is_active) : []
			dispatch({
				type: GET_ORGS_SUCCESS,
				organizations
			})
		}).catch((e) => {
			dispatch({
				type: GET_ORGS_FAILURE,
				error: showError(e) || 'Error pulling organizations.'
			})
		})
}


// Action to get projects for a given organization
export const getProjects = (orgId) => (dispatch) => {
	dispatch({ type: GET_PROJECTS_REQUEST })

	axios.get(`${API}/v1/project/list?organization_id=${orgId}`, { headers: { 'Content-Type': 'application/json', 'flourish-test-header': TEST_HEADER } })
		.then((res) => {
			dispatch({type: GET_PROJECTS_SUCCESS, payload: res.data.data})
		})
		.catch((e) => {
			console.log(showError(e))
		})
}


/* Select functions */

// Action to select a given organization
export const selectOrg = (org) => (dispatch) =>  {
	dispatch({
		type: SELECT_ORG,
		org,
	})
}

// Action to select projects
export function selectProjects(projects) {
	return {
		type: SELECT_PROJECTS,
		projects,
	}
}

// Action to select projects
export function selectProj(proj) {
	return {
		type: SELECT_PROJ,
		proj,
	}
}


/* Set functions */

// Action to trigger a one-time donation
// TODO ensure this route is working
export function setDonateNow(params, stripeToken, callBack, onErrorCallback) {
	const {
		firstname,
		lastname,
		email,
		selected,
		org,
		proj,
	} = params

	return dispatch => {
		dispatch({
			type: DONATE_NOW_REQUEST,
		})

		axios.post(`${API}/v1/donation/process_not_authorized`, {
			first_name: firstname,
			last_name: lastname,
			token: stripeToken.id,
			email,
			project_id: proj.id,
			organization_id: org.id,
			sum: parseFloat(selected),
		}, {
			headers: {
				'Content-Type': 'application/json',
				'flourish-test-header': TEST_HEADER
			}
		} )
			.then(() => {
				dispatch({
					type: DONATE_NOW_SUCCESS,
				})
				dispatch({
					type: MESSAGE,
					message: 'Thanks for donating!'
				})

				callBack()
			})
			.catch((e) => {
				console.log(e)
				onErrorCallback()
				dispatch({
					error: 'Error donating.',
					type: DONATE_NOW_FAILURE,
				})
			})
	}
}

// First action to handle one time authorized donations
export function processOneTimeDonation(token, key, sum, projId, orgId, callBack) {
	return dispatch => {
		dispatch({
			type: ONE_TIME_DONATION_AUTHED_REQUEST,
		})

		exchangeToken(token, key, dispatch).then((bearer) => {
			oneTimeDonationAuthorized(bearer, sum, projId, orgId, dispatch, callBack)
		}).catch((err) => {
			dispatch({
				error: showError(err) || 'Error donating.',
				type: ONE_TIME_DONATION_AUTHED_FAILURE,
			})
		})
	}
}

// Second action to handle one time authorized donations
export function oneTimeDonationAuthorized(bearer, sum, projId, orgId, dispatch, callBack) {
	return axios.post(`${API}/v1/donation/process_authorized`, {
		sum,
		organization_id: parseInt(orgId, 10),
		project_id: parseInt(projId, 10),
	}, {headers: {
		'Content-Type': 'application/json',
		authorization: bearer,
		'flourish-test-header': TEST_HEADER
	}}).then(() => {
		dispatch({
			type: ONE_TIME_DONATION_AUTHED_SUCCESS,
		})

		dispatch({
			type: MESSAGE,
			message: 'Thanks for donating!',
		})

		callBack()
	})
		.catch((e) => {
			dispatch({
				error: showError(e) || 'Error donating.',
				type: ONE_TIME_DONATION_AUTHED_FAILURE,
			})
		})
}

// Action to authorize account during 1-time donations
function exchangeToken(token, key, dispatch) {
	return axios.post(`${API}/v1/user/exchange_otp_token`, {
		otp_token: token,
		key
	}, {
		headers: {
			'Content-Type': 'application/json',
			'flourish-test-header': TEST_HEADER
		}})
		.then((res) => {
			dispatch({
				type: ONE_TIME_DONATION_AUTHED_REQUEST,
			})
			return `Bearer ${res.data.data}`
		})
		.catch((err) => {
			dispatch({
				error: showError(err) || 'Error Authorizing Account.',
				type: ONE_TIME_DONATION_AUTHED_FAILURE,
			})
			return err
		})
}


/* Clear functions */

// Action to clear selected org and proj
export function clearOrgAndProj() {
	return {
		type: CLEAR_ORG_AND_PROJ,
	}
}

// Action to clear projects
export function clearProjects() {
	return {
		type: CLEAR_PROJECTS,
	}
}

// Action to clear the selected project
export function clearSelectedProj() {
	return {
		type: CLEAR_SELECTED_PROJ,
	}
}

// Action to clear entire donate now state
export function clearDonateNow() {
	return {
		type: CLEAR_DONATE_NOW,
	}
}

// Action to clear errors from redux state
export function clearErrors() {
	return {
		type: CLEAR_ERRORS,
	}
}
