import colors from '../colors'

export default {
	bg: {
		color: colors.sunglow,
	},
	forms: {
		first: {
			title: "Organizations",
		},
		second: {
			title: 'Select a project to support'
		},
		third: {
			title: "General Details",
			thanksPart1: "Thank you for donating to the ",
			thanksPart2: "Your support makes an impact!",
			nextTitle: "Payment Information",
			months: [
				{
					value: '01',
					name: 'January',
				},
				{
					value: '02',
					name: 'February',
				},
				{
					value: '03',
					name: 'March',
				},
				{
					value: '04',
					name: 'April',
				},
				{
					value: '05',
					name: 'May',
				},
				{
					value: '06',
					name: 'June',
				},
				{
					value: '07',
					name: 'July',
				},
				{
					value: '08',
					name: 'August',
				},
				{
					value: '09',
					name: 'Septiembre',
				},
				{
					value: '10',
					name: 'October',
				},
				{
					value: '11',
					name: 'November',
				},
				{
					value: '12',
					name: 'December',
				},
			],
			years: [
				{
					value: 18,
					name: 2018,
				},
				{
					value: 19,
					name: 2019,
				},
				{
					value: 20,
					name: 2020,
				},
				{
					value: 21,
					name: 2021,
				},
				{
					value: 22,
					name: 2022,
				},
				{
					value: 23,
					name: 2023,
				},
			],
			fieldErrors: {
				name: "Please verify your name",
				email: "The e-mail is invalid",
				donation: "The donation amount is invalid",
				card: "The card number is invalid",
				cvc: "The CVC number is invalid",
				cardExp: "The card expiration date is invalid",
				zip: "The ZIP code ins invalid",
			},
		},
		thanks: {
			title: "You did it!",
			description1: "Thank you for donating to the ",
			description2: "Your support makes an impact!",
			image: 'https://flourishfiles.blob.core.windows.net/files/Marketing/Illustrations/PNGs/WebHeader%402x.png',
			note: "Please expect a receipt in your email inbox shortly",
			giveBtn: {
				text: "Give Again",
			},
			homeBtn: {
				text: "Go Home",
				link: "/",
			}
		}
	}
}
