import {checkMobileBrowser} from './'

export default function mobileRedirect() {
	if (typeof window !== 'undefined' && checkMobileBrowser()) {
		const loc = window.location.pathname
		if (!loc.includes('/m/') && !loc.includes('/donation/onetime') && !loc.includes('/donations/mobile')) {
			window.location = `/m${loc}`
		}
	}
}
