import React from 'react'
import PropTypes from 'prop-types'

import {
	Listing,
	Item
} from './listStyles'

class List extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<Listing>
				{this.props.list && this.props.list.map((item, i) =>
					<Item color={this.props.color} bullet={this.props.bullet} key={i}>{item}</Item>
				)}
			</Listing>
		)
	}
}

List.propTypes = {
	bullet: PropTypes.string,
	list: PropTypes.array,
	color: PropTypes.string
}

export default List
