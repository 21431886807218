import React from 'react'
import PropTypes from 'prop-types'

import {
	BoxContainer,
} from './boxStyles'

import {
	Title,
	Paragraph,
	Button
} from '../'

const Box = (props) => {
	const { banner, action } = props
	return (
		<BoxContainer {...props} bgColor={props.bgColor} marginLeft={props.marginLeft} marginRight={props.marginRight} borderColor={props.borderColor} >
			{banner &&
				<Paragraph
					style={{ backgroundColor: banner.bgColor,
						position: 'absolute',
						left: 15, right: 15, top: 0,
						height: '2em', lineHeight: '2em',
						borderRadius: '6px 6px 0 0',
					}} color={'white'} fontSize={'1em'} textAlign={'center'}>{banner.text}</Paragraph>
			}
			<Title color={'white'} fontSize={props.titleSize || '50px'} textAlign={'center'}>{props.data.title}</Title>
			<Paragraph color={'white'} fontSize={'16px'} textAlign={'center'}>{props.data.paragraph}</Paragraph>
			{action &&
				<Paragraph
					style={{
						position: 'absolute',
						left: 15, right: 15, bottom: 0,
						height: '2em', lineHeight: '2em',
						borderRadius: '6px 6px 0 0',
					}} color={'white'} fontSize={'1em'} textAlign={'center'}>{action}</Paragraph>
			}
		</BoxContainer>
	)
}

Box.propTypes = {
	data: PropTypes.object,
	borderColor: PropTypes.string,
	marginLeft: PropTypes.string,
	marginRight: PropTypes.string,
	bgColor: PropTypes.string,
	discount: PropTypes.number,
	titleSize: PropTypes.string,
	banner: PropTypes.object,
	action: PropTypes.string
}

export default Box
