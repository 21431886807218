export default {
	bg: {
		image: 'https://flourishfiles.blob.core.windows.net/files/Marketing/LogoPNGs/RGB/Logomark/logo_opacity.png',
		size: '90%',
	},
	form: {
		title: "Sign In To Admin Panel",
		email: {
			label: "Email",
			placeholder: "Email",
		},
		password: {
			label: "Password",
			placeholder: "Password",
		},
		submit: {
			text: "Login",
		}
	},
	forgotPass: {
		text: "Forgot password?",
		link: "/forgot",
	},
	registerLink: {
		text: "New to Flourish?",
		link: "/register",
	}
}
