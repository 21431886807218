import styled from 'styled-components'

import theme from '../../../theme/theme'

export const Bar = styled.div`
    background-color: ${theme.colors.topBar};
    background: ${theme.colors.topBar};
    left: 0;
    position: fixed;
    top: 0;
    z-index: 9;
    width: 100%;
`

export const BarContainer = styled.div`
    align-items: center;
    display: flex;
    height: 72px;
    justify-content: space-between;
`

export const Logo = styled.img.attrs({
	src: theme.images.logo
})`
    margin: 0;
    max-width: 168px;
    width: 100%;
`

export const Menu = styled.ul`
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
`

export const MenuOption = styled.li`
    font-family: ${theme.fonts.primary};
    font-size: 16px;
    margin-bottom: 0;
    background: ${props => props.highlight && 'white'};
    padding: ${props => props.highlight && '10px'};
    border-radius: ${props => props.highlight && '25px'};
    min-width: ${props => props.highlight && '100px'};
    text-align: ${props => props.highlight && 'center'};
    font-weight: ${props => props.highlight && '400'};

    &:not(:last-child) {
        margin-right: 1.5em;
    }
`

export const MenuLink = styled.a`
    color: ${theme.colors.black};
    text-decoration: none;

    &:hover,
    &:focus {
        color: ${theme.colors.menuLink};
    }
`
