import colors from '../colors'

export default {
	bg: {
		color: colors.westCoast,
	},
	content: {
		title: "What Our Customers Think",
		paragraph: "Flourish is dedicated to making sure our customers find success.",
		quotes: [
			{ text: "Flourish’s exciting and innovative app allows individuals to make small donations to their favorite charities in a financially painless yet strategically focused way, all while allowing the donor to see the impact of their giving to the organization.  This method of giving will greatly benefit our communities as charities receive critical sustaining (monthly) funds dedicated to fulfilling their missions!",
				author: 'Cathy R. Sheffield, CAP®, CSPG, CFRE, FCEP',
				title: 'President, <a href="https://thinkgiving.com/" target="_blank">ThinkGiving</a>'
			},
			{ text: "Flourish's app has redefined our parents' and supporters' minds. Everyone likes it because there is no inventory to keep up with while you are fundraising--like when you sell cookies and candy. All our parents love it because there is no soliciting your family and friends for sales.",
				author: 'Johnny Berry',
				title: 'Director of <a href="https://www.trinitysportsorganization.org/" target="_blank">Trinity Sports</a>'
			},
			{ text: "We tried Flourish for our largest fundraising event for our year, a 5k run with more than 4k participants. Flourish was able to deliver targeted marketing, fundraising, and text messages to engage our participants. We look forward to continuing our work with Flourish in the coming months!",
				author: 'Lauren Witson',
				title: 'Marketing Director of <a href="https://katytraildallas.org/" target="_blank">The Katy Trail</a>'
			},
		]
	}
}
