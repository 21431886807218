import styled from 'styled-components'

import theme from '../../../theme/theme'

import { responsiveFontSize } from '../../../util'

const Subtitle = styled.h3`
    color: ${props => props.color ? props.color : theme.colors.black};
    font-family: ${props => props.fontFamily ? props.fontFamily : theme.fonts.secondary};
    font-size: ${props => props.fontSize ? props.fontSize : '16px'};
    margin-left: ${props => props.marginLeft ?props.marginLeft : 'auto'};
    margin-right: ${props => props.marginRight ? props.marginRight : 'auto'};
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : '0.3em'};
    text-align: ${props => props.textAlign ? props.textAlign : 'unset'};

    @media (max-width: ${theme.breakPoints.small}) {
        font-size: ${props => props.fontSize ? responsiveFontSize(props.fontSize, 10) : responsiveFontSize('16px', 20)};
    }
`

export default Subtitle
