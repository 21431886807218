export default {
	bg: {
		color: false,
		image: 'https://flourishfiles.blob.core.windows.net/files/Marketing/LogoPNGs/RGB/Logomark/logo_opacity.png',
		size: '750px 750px',
		position: '-190px -375px',
	},
	content: {
		firstPart: {
			title: "We focus on building relationships, not just transactions.",
			paragraph: false,
			labelBtn: false,
			firstBtn: {
				text: "Schedule a Call Today!",
				link: 'https://roundupapp.com/kickoff',
			},
			secondBtn: {
				text: "Sign Up Today!",
				link: '/pricing',
			},
			image: 'https://flourishfiles.blob.core.windows.net/files/Marketing/Illustrations/PNGs/WebNPHeader%402x.png',
		},
		secondPart: {
			image: 'https://flourishfiles.blob.core.windows.net/files/Marketing/Website/non_profit_intro.png',
			image2: 'https://storage.googleapis.com/resources.flourishchange.com/Marketing/Website/donorLifecycle.png',
			title: "The key is valuing the entire donor lifecycle. ",
			list: [
				"Engage current and potential donors",
				"Understand the wants of your donors",
				"Fundraise from donor prospects",
				"Retarget to maximize value of donors"
			]
		}
	}
}
