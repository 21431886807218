import React from 'react'
import PropTypes from 'prop-types'
import MediaQuery from 'react-responsive'

import {
	Container,
	Row,
	Column,
} from '../../layouts'

import {
	Title,
	Subtitle,
	Button,
	Input,
	Select
} from '../'

import {
	FormContainer,
	P as Paragraph
} from './NewOrgFormStyles'

import theme from '../../../theme/theme'
import states from '../../../theme/states'

class OneTimeDonate extends React.Component {
	constructor (props) {
		super(props)
	}

	render () {
		return (
			<FormContainer>
				<Container>
					<Row>
						<Column>
							<Title fontSize={'33px'} marginLeft={'0'}>Where can we find your organization?</Title>
							<Subtitle fontSize={'21px'} marginLeft={'0'}>This helps us verify your nonprofit.</Subtitle>
						</Column>
					</Row>
					<Row>
						<Column lateralPaddings={'10px'} maxWidth={'100%'}>
							<Paragraph>Organization Street Address</Paragraph>
							<Input name="org_address_street_address" type="text" onChange={this.props.handleFormEdit}  minLength="4" shadow autoComplete="off" required="required" />
						</Column>
					</Row>
					<MediaQuery maxWidth={theme.breakPoints.xsmall}>
						<Row />
					</MediaQuery>
					<Row>
						<Column lateralPaddings={'10px'} maxWidth={'50%'}>
							<Paragraph>Organization City</Paragraph>
							<Input name="org_address_city" type="text" onChange={this.props.handleFormEdit}  minLength="4" shadow autoComplete="off" required="required" />
						</Column>
						<MediaQuery maxWidth={theme.breakPoints.xsmall}>
							<Row />
						</MediaQuery>
						<Column lateralPaddings={'10px'} maxWidth={'50%'}>
							<Paragraph>Organization State</Paragraph>
							<Select name="org_address_state_id" type="select" onChange={this.props.handleFormEdit} shadow required="required">
								<option value={-1} disabled selected>-</option>
								{states.map((state) => <option key={state.value} value={state.value}>{state.text}</option>)}
							</Select>
						</Column>
					</Row>
					<Row>
						<Column lateralPaddings={'10px'} maxWidth={'50%'}>
							<Paragraph>Organization Zip</Paragraph>
							<Input name="org_address_zip" type="text" onChange={this.props.handleFormEdit}  minLength="4" shadow autoComplete="off" required="required" />
						</Column>
						<MediaQuery maxWidth={theme.breakPoints.xsmall}>
							<Row />
						</MediaQuery>
						<Column lateralPaddings={'10px'} maxWidth={'50%'}>
							<Paragraph>Organization EIN</Paragraph>
							<Input name="org_EIN" type="text" onChange={this.props.handleFormEdit}  minLength="4" shadow autoComplete="off" />
						</Column>
					</Row>
					<Row>
						<Column>
							{this.props.errors && <Subtitle color="red" fontSize={'21px'} marginLeft={'0'}>{this.props.errors}</Subtitle>}
						</Column>
					</Row>
					<Row style={{ justifyContent: "center", alignItems: "center" }}>
						<Button onClick={this.props.onBack} marginRight={'20px'} maxWidth={'150px'} hoverBg={theme.colors.salomie}>Back</Button>
						<Button onClick={this.props.onSubmit} maxWidth={'150px'} hoverBg={theme.colors.salomie}>Next</Button>
					</Row>
				</Container>
			</FormContainer>
		)
	}
}

OneTimeDonate.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	values: PropTypes.array,
	onChange: PropTypes.func,
	onCustomChange: PropTypes.func,
	isCustom: PropTypes.bool,
	selectedOption: PropTypes.number,
	submitBtn: PropTypes.string,
	errors: PropTypes.any,
	valid: PropTypes.bool,
	submit: PropTypes.func,
	loading: PropTypes.bool,
	onSubmit: PropTypes.func,
	onBack: PropTypes.func,
	handleFormEdit: PropTypes.func
}

export default OneTimeDonate
