import React from 'react'
import MediaQuery from 'react-responsive'

import {
	Container,
	Section,
	Row,
	Column,
} from '../../layouts'

import {
	Title
} from '../FooterBlock/footerBlockStyles'

import {
	Paragraph,
	Image,
	FooterMenu,
	FooterBlock,
	SocialBtn,
	Link,
} from '../'

import theme from '../../../theme/theme'

import {checkMobileBrowser} from '../../../util'

const {
	footer,
} = theme.sections

const Footer = () =>{
	let mobileHref = ''
	if (checkMobileBrowser()) {
		mobileHref = '/m'
	}

	const navDataMenu = footer.content.navigation.menu.map((mItem) => Object.assign({}, {...mItem, link: `${mobileHref}${mItem.link}`}))
	const navData = {...footer.content.navigation, menu: navDataMenu}

	return(<Section bg={footer.bg} paddingTop={'3em'} paddingBottom={'1em'}>
		<Container>
			<Row marginBottom={'1em'}>
				<Column maxWidth={'30%'} smallMaxWidth={'22%'}>
					<Image src={footer.content.footerLogo} marginBottom={'2em'} marginRight={'auto'} width={'auto'} maxHeight={'160px'} />
					<Paragraph fontSize={'14px'} lineHeight={'1.3em'} dangerouslySetInnerHTML={{ __html: footer.content.footerDescription }} />
				</Column>
				<Column maxWidth={'22%'} smallMaxWidth={'22%'}>
					<FooterMenu data={navData} />
					<FooterMenu data={footer.content.legal} />
				</Column>
				<Column maxWidth={'22%'} smallMaxWidth={'30%'}>
					<FooterBlock data={footer.content.contact} />
				</Column>
				<Column maxWidth={'26%'} smallMaxWidth={'23%'}>
					<Title marginLeft={'12px'} textAlign={'left'} fontSize={'22px'} marginBottom={'0.8em'}>{footer.content.social.title}</Title>
					<Row style={{display: 'flex'}}>
						<Column row vAlign={'center'}>
							<SocialBtn href={footer.content.social.twitter}>
								<i  className="fa fa-twitter" />
							</SocialBtn>
							<SocialBtn href={footer.content.social.facebook}>
								<i className="fa fa-facebook" />
							</SocialBtn>
							<SocialBtn href={footer.content.social.instagram}>
								<i className="fa fa-instagram" />
							</SocialBtn>
						</Column>
					</Row>
					<Title marginLeft={"12px"} textAlign={'left'} fontSize={"22px"} marginTop={'0.8em'} style={{marginTop: '1em', marginBottom: '0em'}}>Download Today!</Title>
					<Row marginBottom={'0'} style={{jusifyContent: 'center', alignItems: 'center', marginTop: '1em'}}>
						<Column width={'100%'} style={{jusifyContent: 'center'}}>
							<Link href={footer.content.social.appstore.link} marginRight={'auto'} style={{jusifyContent: 'center', padding: 'auto', display: 'flex', marginBottom: '1.5em'}}>
								<Image src={footer.content.social.appstore.image} marginBottom={'0'} height={'48px'} maxWidth={'144px'} smallWidth={'none'}/>
							</Link>
							<Link href={footer.content.social.googlePlay.link} marginRight={'auto'} style={{jusifyContent: 'center', alignItems: 'center', padding: 'auto', display: 'flex', marginBottom: '1.5em'}}>
								<Image src={footer.content.social.googlePlay.image} marginBottom={'0'} height={'48px'} maxWidth={'162px'}/>
							</Link>
						</Column>
					</Row>
				</Column>
			</Row>
			<Row>
				<Column>
					<Paragraph fontSize={'13px'} textAlign={'center'} dangerouslySetInnerHTML={{ __html: footer.content.copyright }} />
				</Column>
			</Row>
		</Container>
	</Section>)
}

export default Footer
