import React from 'react'

import {
	Row,
	Column,
} from '../../layouts'

import {
	Subtitle,
	Image,
	Paragraph
} from '../'

const BlogAuthor = (props) => {
	switch (props.author){
	case 'laura':
		return (
			<Row>
				<Column lateralPaddings={'0px'}>
					<Subtitle>Laura Soundy</Subtitle>
					<Image
						style={{ marginTop: 0 }}
						width={'100px'}
						src={'https://ca.slack-edge.com/T8HLXLQS0-UB50SKTJN-f9f177121b20-72'} marginTop={'2em'} />
				</Column>
				<Paragraph>
        	Originally from Sioux Falls, South Dakota, Laura graduated from Baylor University and joined our team in June, 2018. Throughout her college career, she planned and directed multiple fundraising events that raised thousands of dollars for the organizations she was involved with. Outside of the office, Laura loves to compete in sports, play piano, and spend time with friends. She plans to eventually pursue a law degree and continue to work in the nonprofit space.
				</Paragraph>
			</Row>
		)
	case 'braden':
		return (
			<Row>
				<Column lateralPaddings={'0px'}>
					<Subtitle>Braden Fineberg</Subtitle>
					<Image
						style={{ marginTop: 0 }}
						width={'100px'}
						src={'https://flourishfiles.blob.core.windows.net/files/Marketing/Headshots/Braden.png'} marginTop={'2em'} />
				</Column>
  Aenean in hendrerit nulla, ut auctor ligula. Sed elementum sagittis ipsum, at placerat lorem pulvinar ac. Proin varius nibh sit amet efficitur tempor. Curabitur tristique ut sapien ut pulvinar. Phasellus vitae tellus at lacus malesuada semper non eget ligula. Sed imperdiet lobortis scelerisque. Fusce bibendum mattis suscipit. Nulla vel ligula nulla. Morbi eu magna euismod, rhoncus dolor at, euismod augue. Aliquam commodo aliquam dui at auctor. Donec aliquet condimentum felis eget finibus. Praesent in dolor ac ipsum finibus dignissim. Cras eleifend mauris dui, vel pretium enim malesuada in.
			</Row>
		)
	}
}

export default BlogAuthor
