import styled from 'styled-components'

import theme from '../../../theme/theme'


export const ProgressCell = styled.div`
    background-color: ${props => props.active ? theme.colors.crusta : theme.colors.silver};
    height: 10px;
    margin-right: 5px;
    width: 100%;
`
