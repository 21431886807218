import colors from '../colors'

export default {
	bg: {
		color: colors.wewak,
	},
	content: {
		title: "Step 1: Engage",
		paragraph: "Text Messages are 17x more engaging than emails.",
		image1: 'https://storage.googleapis.com/resources.flourishchange.com/Marketing/Website/smsExample.png',
		image2: 'https://storage.googleapis.com/resources.flourishchange.com/Marketing/Website/FacebookExample.png'
	}
}
