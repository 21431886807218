import React from 'react'
import PropTypes from 'prop-types'

import {
	Row
} from '../../layouts'

import {
	ProgressCell,
} from './FormProgressStyles'

class FormProgress extends React.Component {
	render () {
		return (
			<Row style={{ width: '100%', margin: 0 }}>
				{Array.apply(null, { length: this.props.totalPositions }).map((e, i) => {
					return (
						<ProgressCell key={i} active={i <= this.props.currentPosition}/>
					)
				}
				)}
			</Row>
		)
	}
}

FormProgress.propTypes = {
	children: PropTypes.object,
	style: PropTypes.object,
	totalPositions: PropTypes.number,
	currentPosition: PropTypes.number
}

export default FormProgress
