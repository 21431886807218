import styled from 'styled-components'

import theme from '../../../theme/theme'

import { responsiveFontSize } from '../../../util'

const Title = styled.h2`
    color: ${props => props.color ? props.color : theme.colors.black};
    font-family: ${theme.fonts.secondary};
    font-size: ${props => props.fontSize ? props.fontSize : '16px'};
    font-weight: 500;
    margin: 0 auto;
    ${props => props.marginLeft ? 'margin-left:' + props.marginLeft + ';' : false}
    ${props => props.marginRight ? 'margin-right:' + props.marginRight + ';' : false}
    margin-top: ${props => props.marginTop ? props.marginTop : '0'};
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : '0.3em'};
    ${props => props.maxWidth ? 'max-width:' + props.maxWidth + ';' : ''}
    text-align: ${props => props.textAlign ? props.textAlign : 'unset'};

    @media (max-width: ${theme.breakPoints.small}) {
        font-size: ${props => props.fontSize ? responsiveFontSize(props.fontSize, 20) : '16px'};
    }
    @media (max-width: ${theme.breakPoints.xsmall}) {
        font-size: ${props => props.fontSize ? responsiveFontSize(props.fontSize, 25) : '16px'};
    }
`

export default Title
