import colors from './colors'

import intro from './sections/intro'
import callToAction from './sections/call-to-action'
import donationAbout from './sections/donations-about'
import securityMatters from './sections/security-matters'
import leaveImpact from './sections/leave-impact'
import frequentlyQuestions from './sections/frequently-questions'
import wantMore from './sections/want-more'
import footer from './sections/footer'
import introNonprofit from './sections/intro-nonprofits'
import beOnline from './sections/be-online'
import forwardThinking from './sections/forward-thinking'
import whatOffer from './sections/what-offer'
import contactSales from './sections/contact-sales'
import securityPage from './sections/security-page'
import privacy from './sections/privacy'
import termsConditions from './sections/terms-conditions'
import login from './sections/login'
import donateNow from './sections/donateNow'
import oneTimeDonation from './sections/one-time-donation'
import testimonials from './sections/testimonials'
import fundraising from './sections/fundraising'
import retarget from './sections/retarget'

import { DASHBOARD } from './actionTypes'

const theme = {
	colors: {
		...colors,

		black: colors.mineShaft,
		topBar: colors.sunglow,
		menuLink: colors.crusta,
		buttons: colors.sunglow,
		buttonsHovered: colors.salomie,
		buttonsShadow: 'rgba(74, 74, 74, 0.2)',
		link: colors.crusta,
	},
	breakPoints: {
		xsmall: '767px',
		small: '769px',
		medium: '992px',
		large: '1200px',
	},
	fonts: {
		primary: "'Work Sans', sans-serif",
		secondary: "'Frank Ruhl Libre', serif",
	},
	images: {
		logo: 'https://flourishfiles.blob.core.windows.net/files/Marketing/LogoPNGs/RGB/Wordmark/MarigoldType.png',
		isotype: 'https://flourishfiles.blob.core.windows.net/files/Marketing/LogoPNGs/RGB/Logomark/LogoSmall.png',
	},
	mainMenu: [
		{
			name: 'Home',
			link: '/#intro'
		},
		{
			name: 'Nonprofits',
			link: '/nonprofits'
		},
		{
			name: 'Nonprofit Login',
			link: `${DASHBOARD}/login`
		},
		{
			name: 'Donor Sign In & Sign Up',
			link: 'https://my.flourishchange.com/login',
			highlight: true
		}
	],
	sections: {
		intro,
		callToAction: { ...callToAction },
		donationAbout: { ...donationAbout },
		securityMatters: { ...securityMatters },
		leaveImpact: { ...leaveImpact },
		frequentlyQuestions: { ...frequentlyQuestions },
		wantMore: { ...wantMore },
		footer: { ...footer },
		introNonprofit: { ...introNonprofit },
		beOnline: { ...beOnline },
		forwardThinking,
		retarget,
		whatOffer: { ...whatOffer },
		contactSales: { ...contactSales },
		securityPage: { ...securityPage },
		privacy: { ...privacy },
		termsConditions: { ...termsConditions },
		fundraising,
		donateNow: { ...donateNow },
		login: { ...login },
		oneTimeDonation: { ...oneTimeDonation },
		testimonials: { ...testimonials }
	},
	redirects: {
		forgot: `${DASHBOARD}/forgot`,
		reset: `${DASHBOARD}/reset`,
		myDonationChoices: `${DASHBOARD}/donations/mobile`,
		register: `${DASHBOARD}/register`,
		unsubscribe: `${DASHBOARD}/unsubscribe`,
		brandAmbassador: `https://upenn.co1.qualtrics.com/jfe/form/SV_9GouEkG2lf3Htcx`
	}
}

export default theme
