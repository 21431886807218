import React from 'react'
import PropTypes from 'prop-types'

import {
	Container,
	Row,
	Column,
} from '../../layouts'

import {
	Button,
	BtnField,
	LoadingData,
	Paragraph,
	Title
} from '../'

import  {
	FormContainer,
	ListField,
	ListItem,
} from './projectsFormStyles'

import theme from '../../../theme/theme'

class ProjectsForm extends React.Component {
	constructor(props) {
		super(props)

		this.selectOption = this.selectOption.bind(this)
	}

	selectOption(proj) {
		this.props.onSelectProj(proj)
	}

	render() {
		return (
			<FormContainer>
				<Container>
					<Row>
						<Column maxWidth={'60%'}>
							{this.props.errors && <Paragraph color={theme.colors.red}>{this.props.errors}</Paragraph>}
							<Title fontSize={'33px'} marginLeft={'0.2em'} marginTop={'0.2em'}>{this.props.title}</Title>
						</Column>
					</Row>
					<Row>
						<Column>
							{this.props.errors && <Paragraph>{this.props.errors}</Paragraph>}
							{this.props.projects ?
								<ListField>
									{this.props.projects.length > 0 && this.props.projects.map((item, i) =>
										<ListItem key={i} className={(this.props.selectedProj && this.props.selectedProj.id === item.id) ? 'selected' : ''} onClick={this.selectOption.bind(this, item)}>{item.name}</ListItem>
									)}
								</ListField> :
								<LoadingData />
							}
						</Column>
					</Row>
					<Row style={{marginTop: '1em'}}>
						<Column vAlign={'end'} row>
							<Button bgColor={theme.colors.crusta} fontColor={theme.colors.white} marginRight={'0.5em'} maxWidth={'110px'} onClick={this.props.back}>{'Back'}</Button>
							<BtnField type="button" marginLeft={'0.5em'} marginBottom={'0'} maxWidth={'110px'} onClick={this.props.next} disabledBtn={this.props.selectedProj && this.props.selectedProj.id ? true : false}>{'Next'}</BtnField>
						</Column>
					</Row>
				</Container>
			</FormContainer>
		)
	}
}

ProjectsForm.propTypes = {
	projects: PropTypes.array,
	title: PropTypes.string,
	selectedProj: PropTypes.object,
	onSelectProj: PropTypes.func,
	next: PropTypes.func,
	back: PropTypes.func,
	errors: PropTypes.array,
}

export default ProjectsForm
