import colors from '../colors'

export default {
	bg: {
		color: colors.sunglow,
	},
	forms: {
		title: "You make a difference",
		subtitle: "Your one time donation",
		values: [
			{
				name: "$1",
				value: 1,
			},
			{
				name: "$5",
				value: 5,
			},
			{
				name: "$10",
				value: 10,
			},
			{
				name: "$25",
				value: 25,
			},
			{
				name: "$50",
				value: 50,
			},
			{
				name: "Custom",
				value: -1,
			}
		],
		submitBtn: "Donate now",
		thanks: {
			title: "You did it!",
			description1: "Thank you for donating",
			description2: "Your support makes an impact!",
			image: 'https://flourishfiles.blob.core.windows.net/files/Marketing/Illustrations/PNGs/WebNPHeader%402x.png',
			note: "Please expect a receipt in your email inbox shortly",
			giveBtn: {
				text: "Give Again",
			},
			homeBtn: {
				text: "Back to App",
				link: "flourish://",
			}
		}
	},
}
