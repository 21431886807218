import colors from '../colors'

export default {
	bg: {
		color: colors.shadowGreen,
	},
	content: {
		title: "What We Offer",
		paragraph: "Pick and choose products to meet your needs, or let us help do it all!",
		oneClickRelationships: {
			list: [
				"4,500 text messages/mo",
				"2 hours of our time to build/optimize automated conversations",
				"Delivery, Open & Click Reports"
			],
			pricing: {
				1: "$250/mo",
				2: "$2,500/yr"
			},
			pricing2: ''
		},
		marketing: {
			list: [
				"1 highly targeted social media campaign/mo",
				"Management of Google Ad credits*",
				"Website and visitor analytics",
				"Click and conversion reports"
			],
			pricing: {
				1: "$750/mo",
				2: "$7,500/yr"
			},
			pricing2: '+ ad. costs'
		},
		app: {
			list: [
				"Customizable organization profile on the Flourish App",
				"Admin dashboard to view live app data",
				"Create and edit projects within the app"
			],
			pricing: {
				1: "10%",
				2: "10%"
			},
			pricing2: 'of donations'
		},
		analytics: {
			list: [
				"Customized Marketing Campaigns",
				"Fundraising Event Optimization",
				"Customized Deployment Plans"
			],
			pricing: {
				1: "$500/mo",
				2: "$5,000/yr"
			},
		},
		grant: {
			list: [
				"Unlimited Campaigns",
				"Live click and impression dashboard",
				"Weekly campaign optimization",
				"Monthly PDF reports"
			],
			pricing: {
				1: "$400/mo",
				2: "$4,400/yr"
			}
		},
		grantPlus: {
			list: [
				"Unlimited Campaigns",
				"Live click and impression dashboard",
				"Weekly campaign optimization",
				"Monthly PDF reports",
				"1 highly targeted social media campaign",
				"Website and visitor analytics",
				"A/B test your website for conversion*",
				"Live ad retargeting"
			],
			pricing: {
				1: "$750/mo",
				2: "$8,250/yr"
			}
		},
		mobileList: {
			1: [
				{
					question: "One-Click Relationships $250/mo",
					answer: "With One-Click relationships, you will recieve 4,500 text messages/mo, 2 hours of our time to build/optimize automated conversations, and delivery, open & click reports.",
				},
				{
					question: 'Targeted Marketing $750/mo+ad. spend',
					answer: 'With targeted marketing, you will recieve 1 highly targeted social media campaign/mo, access to monthly Google ad credits*, 4 hours of time to craft campaign, and click & conversion reports.'
				},
				{
					question: 'The Flourish App $100/mo + 8% of donations > $1,000',
					answer: 'The app comes with a customizable organization profile on the Flourish App, an admin dashboard to view live app data, and the ability to create and edit projects within the app.'
				},
				{
					question: 'Deep Analytics $350/mo',
					answer: 'Deep analytics provides you with a list of your top 20 prospects and donors with the highest lifetime value. We also provide a personalization analysis for each donor using Flourish.'
				}],
			2: [
				{
					question: "One-Click Relationships $2,500/yr",
					answer: "With One-Click relationships, you will recieve 4,500 text messages/mo, 2 hours of our time to build/optimize automated conversations, and delivery, open & click reports.",
				},
				{
					question: 'Targeted Marketing $7,500/yr+ad. spend',
					answer: 'With targeted marketing, you will recieve 1 highly targeted social media campaign/mo, access to monthly Google ad credits*, 4 hours of time to craft campaign, and click & conversion reports.'
				},
				{
					question: 'The Flourish App $1,000/yr + 8% of donations > $1,000',
					answer: 'The app comes with a customizable organization profile on the Flourish App, an admin dashboard to view live app data, and the ability to create and edit projects within the app.'
				},
				{
					question: 'Deep Analytics $3,500/yr',
					answer: 'Deep analytics provides you with a list of your top 20 prospects and donors with the highest lifetime value. We also provide a personalization analysis for each donor using Flourish.'
				}
			]},
		mobileGrantList: [
			{
				question: "Just the Basics $400/mo",
				answer: "With the basics, we will get you the $10,000/mo grant, set up basic website analytics, manage your campaigns, and grant you access to a live dashboard to track the impact of your ads."
			},
			{
				question: "The Full Package $750/mo",
				answer: "With this package, you get comprehensive website analytics and tracking, A/B testing*, conversion optimization, and everything included in our basics package."
			}

		],
		list: [
			"Access to an administrative dashboard with donor analytics",
			"Free processing up to $1,000/month in donations",
			"Additional donations above $1,000/month are charged at 8%",
			"Includes a customized marketing campaign",
			"Proprietary marketing algorithm to grow your donor base",
		],
		firstColum: {
			2: {
				title: "$850/mo",
			},
		},
		enterprise: {
			2: {
				title: "$8,500/yr",
			}
		},
		products: [
			'One-Click Relationships',
			"Targeted Marketing",
			"The Flourish App",
			"Account Manager"
		]
	},
	btn: {
		text: "Schedule a Call",
		link: 'https://roundupapp.com/kickoff',
	},
	products: {
		'app': 'The Flourish App',
		'marketing': 'Targeted Marketing',
		'analytics': 'Deep Analytics',
		'oneClick': 'One Click Relationships',
		"grant": 'Just the Basics Grant Managment',
		"grantPlus": "The Full Package Grant Management",
		"monthly": 'Everything, paid monthly',
		'annual': 'Everything, paid annually'
	},
	prices: {
		1: {
			'app': 100,
			'marketing': 750,
			'analytics': 300,
			'oneClick': 250,
			"monthly": 850,
			'annual': 8500,
			"grant": 400,
			"grantPlus": 750
		},
		2: {'app': 1000,
			'marketing': 7500,
			'analytics': 3000,
			'oneClick': 2500,
			"monthly": 850,
			'annual': 8500,
			"grant": 4400,
			"grantPlus": 8250
		}
	}
}
