import styled from 'styled-components'

import theme from '../../../theme/theme'

export const Listing = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    padding-left: 1.5em;
`

export const Item = styled.li`
    font-family: ${theme.fonts.primary};
    font-size: 20px;
    letter-spacing: 0;
    padding-left: 0.8em;
    position: relative;
    color: ${props => props.color ? props.color : "black"};

    &:before {
        background-image: url(${props => props.bullet ? props.bullet : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAAGzs1ytAAAABGdBTUEAALGPC/xhBQAAAkVJREFUOBGtVd9LVEEU/qa926JZrvUk6ItmWRhbuFhJkkT/hP0PvfbUQ0EUZYIUadBbRRtGIEj1FNijsma+RFDQD7cWzRZ6MSi363fuOHfu3p3dUpyHO+d855tvzpx75l4FDj/f68usjIHePHYIgpazwFw2Ekl67TqSaAb+rC1qp/wzYFuBwLUPzdrTDxx9BVE3Q/kLx9tExwB6ViVKZR8xtaFIoOjU19qGtqHtBb7ZiCeQocHAsYushb8BSx6u3cNg3KBCRZqVca9FHzjVFuA6BzHVTiDzEngzACSaWJnpgCDlNsOS/d/Ax4u2dBGSIUtxvtFpNUC92dR9tv4ClVPZ/Dln2d3qKhepp5tiUX+oPvnwBHDkecivTT6UAxo6gNILB7lrDGg6pgPdD4DGLmD5MfD1dki2dRbiwXvA6jsSu4HvT4HFmyFRDJvG/Em++TVNXJkCvlyrIFaSxXt9AvjxDPh8uYoowCbqjCLTUCWnTBxMen0br7u8wIT3xuNb87l5MpHxeHelh7ZJVFJhgtSUKv9XJ8uSquHtAw7cBVLt8VDrZopsF3tpLdiw32KFW8DS/dC3TRRCNFSKrdnJhnobRXnjd1NwXPeOiSw9BAqjxgvnauHOESB9WhPKq8D788CvDxS8A+zqCRdi+Qm797r1Y5a7FM2ngI5h9nkyRqe7MsnmvVKNxxC3sCGlz3CDqywNDya34dMlRoIfkGHUnOsL11z274DcJn5Etnvw+ymS+idXvkFrkO5W+7rIJKd56y6ozExhHfuIqWSeI+TNAAAAAElFTkSuQmCC'});
        content: '';
        display: block;
        height: 22px;
        left: -1.5em;
        position: absolute;
        top: 2px;
        width: 22px;
    }

    @media (max-width: ${theme.breakPoints.small}) {
        margin-bottom: 1em;
    }
`
