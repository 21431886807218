import React from 'react'
import PropTypes from 'prop-types'

import {
	Container,
	Row,
	Column,
} from '../../layouts'

import {
	Title,
	Subtitle,
	Paragraph,
	BtnField,
	CheckBox,
	InputField,
	LoadingData,
	Button
} from '../'

import {
	FormContainer,
} from './oneTimeDonateStyles'

import theme from '../../../theme/theme'

class OneTimeDonate extends React.Component {
	render() {
		return (
			<FormContainer>
				<Container>
					<Row>
						<Column>
							<Title fontSize={'33px'} marginLeft={'0'}>{this.props.title}</Title>
							<Subtitle fontSize={'21px'} marginLeft={'0'}>{this.props.subtitle}</Subtitle>
						</Column>
					</Row>
					<Row marginBottom={'0'}>
						<Column>
							{this.props.values && this.props.values.map((item, i) =>
								<CheckBox name={'donation'} key={i} label={item.name} defaultSelected={item.value === this.props.selectedOption} value={item.value} onChange={this.props.onChange} />
							)}
						</Column>
					</Row>
					{this.props.isCustom &&
						<Row>
							<Column maxWidth={'300px'}>
								<InputField label="Amount $" type="number" onChange={this.props.onCustomChange} min="0" />
							</Column>
						</Row>
					}
					{this.props.errors && <Paragraph color={theme.colors.red}>{this.props.errors}</Paragraph>}
					<Row>
						<Column>
							{this.props.loading ?
								<LoadingData/> :
								<div style={{ maxWidth: 200 }}>
									<BtnField maxWidth={'180px'} onClick={this.props.submit} disabled={this.props.valid}>
										{this.props.submitBtn}
									</BtnField>
									<Button
										href={this.props.redirect}
										maxWidth={'180px'}
										style={{ backgroundColor: 'white', border: `1px solid ${theme.colors.sunglow}`, fontWeight: 500 }}>
											Back to app
									</Button>
								</div>
							}
						</Column>
					</Row>
				</Container>
			</FormContainer>
		)
	}
}

OneTimeDonate.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	values: PropTypes.object,
	onChange: PropTypes.func,
	onCustomChange: PropTypes.func,
	isCustom: PropTypes.bool,
	selectedOption: PropTypes.number,
	submitBtn: PropTypes.string,
	errors: PropTypes.any,
	valid: PropTypes.bool,
	submit: PropTypes.func,
	loading: PropTypes.bool,
	redirect: PropTypes.string
}

export default OneTimeDonate
