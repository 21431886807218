import styled from 'styled-components'

import theme from '../../../theme/theme'

const Input = styled.input`
    appearance: none;
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.dustyGray};
    border-radius: 5px;
    ${props => props.shadow ? 'box-shadow: 0 4px 7px 0 rgba(120, 120, 120, 0.3);' : ''}
    font-family: ${theme.fonts.primary};
    font-size: 16px;
    height: 44px;
    outline: none;
    padding: 0.5em 0.8em;
    transition: border 1s;

    &::placeholder {
        color: ${theme.colors.black};
        font-family: ${theme.fonts.primary};
        font-size: 16px;
    }

    &:focus {
        border: 2px solid ${theme.colors.sunglow};
    }
`

export default Input
