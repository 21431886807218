import React from 'react'
import PropTypes from 'prop-types'
import ReCAPTCHA from "react-google-recaptcha"

import {
	Container,
	Row,
	Column,
} from '../../layouts'

import {
	Title,
	Subtitle,
	Button,
	TextArea,
	LoadingData
} from '../'

import {
	FormContainer,
	P as Paragraph
} from './NewOrgFormStyles'

import theme from '../../../theme/theme'

class OneTimeDonate extends React.Component {
	constructor (props) {
		super(props)
	}

	render () {
		return (
			<FormContainer>
				<Container>
					<Row>
						<Column>
							<Title fontSize={'33px'} marginLeft={'0'}>What drives your cause?</Title>
							<Subtitle fontSize={'21px'} marginLeft={'0'}>FYI, donors will see this text.</Subtitle>
						</Column>
					</Row>
					<Row>
						<Column lateralPaddings={'10px'} maxWidth={'100%'}>
							<Paragraph>Organization Mission</Paragraph>
							<TextArea maxlength={240} rows="7" name="org_mission" type="textarea" onChange={this.props.handleFormEdit}  minLength="4" shadow autoComplete="off" required="required" />
						</Column>
					</Row>
					<Row>
						<Column>
							{this.props.errors && <Subtitle color="red" fontSize={'21px'} marginLeft={'0'}>{this.props.errors}</Subtitle>}
						</Column>
					</Row>
					<Row style={{ justifyContent: "center", alignItems: "center" }}>
						<Button onClick={this.props.onBack} marginRight={'20px'} maxWidth={'150px'} hoverBg={theme.colors.salomie}>Back</Button>
						<Button onClick={this.props.onSubmit}  maxWidth={'150px'} hoverBg={theme.colors.salomie}>Next</Button>
					</Row>
				</Container>
			</FormContainer>
		)
	}
}

OneTimeDonate.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	values: PropTypes.array,
	onChange: PropTypes.func,
	onCustomChange: PropTypes.func,
	isCustom: PropTypes.bool,
	selectedOption: PropTypes.number,
	submitBtn: PropTypes.string,
	errors: PropTypes.any,
	pending: PropTypes.bool,
	valid: PropTypes.bool,
	submit: PropTypes.func,
	loading: PropTypes.bool,
	onSubmit: PropTypes.func,
	onBack: PropTypes.func,
	handleFormEdit: PropTypes.func,
	recaptchaRef: PropTypes.any,
	captchaChange: PropTypes.func
}

export default OneTimeDonate
