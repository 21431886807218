import styled from 'styled-components'

const BetaLabel = styled.img`
    height: auto;
    left: ${props => props.left ? props.left : '0'};
    margin-bottom: 0;
    margin-left: ${props => props.marginLeft ? props.marginLeft : '0'};
    margin-right: ${props => props.marginRight ? props.marginRight : '0'};
    max-width: ${props => props.maxWidth ? props.maxWidth : 'unset'};
    position: absolute;
    right: ${props => props.right ? props.right : '0'};
    top: ${props => props.top ? props.top : '0'};
    z-index: 5;
`

export default BetaLabel