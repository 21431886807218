import React from 'react'
import MediaQuery from 'react-responsive'

import theme from '../../../theme/theme'

import {
	Row,
	Column,
} from '../'

import {
	Input,
	BtnField,
	Paragraph,
} from '../../ui'

const { wantMore } = theme.sections

class ContactForm extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			fname: '',
			lname: '',
			email: '',
			formSubmitted: false,
			errors: {},
		}

		this.handleContactFormEdit = this.handleContactFormEdit.bind(this)
		this.submitContactForm = this.submitContactForm.bind(this)
	}

	handleContactFormEdit(e) {
		this.setState({ [e.target.name]: e.target.value })
	}


	submitContactForm(e) {
		e.preventDefault()
		e.stopPropagation()

		const { email, fname, lname } = this.state

		fetch('https://forms.hubspot.com/uploads/form/v2/4829666/1379d244-f139-47d3-a3f3-cf5cf38149bc', {
			method: 'POST',
			mode: 'no-cors',
			headers: new Headers(
				{ "Content-Type": 'application/x-www-form-urlencoded' },
			),
			body: `email=${email}&firstname=${fname}&LastName=${lname}&lifecyclestage=Lead&hubspot_owner_id=33280171`
		})

		this.setState({ fname: '', lname: '', email: '', formSubmitted: true })
	}

	render() {
		if (this.state.formSubmitted) {
			return (
				<div>
					<Row>
						<Paragraph color={theme.colors.white} maxWidth={'706px'} fontSize={'21px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'2.5em'} fontWeight={'200'}>
							{wantMore.form.thanks}
						</Paragraph>
					</Row>
					<Row>
						<Column>
							<BtnField onClick={()=>this.setState({ formSubmitted: false })} marginLeft={'auto'} marginRight={'auto'} maxWidth={'300px'} hoverBg={theme.colors.salomie}>{wantMore.form.reset}</BtnField>
						</Column>
					</Row>
				</div>
			)
		}
		return (
			<form onSubmit={(e) => {
				this.submitContactForm(e)
			}}>
				<Row>
					<Column lateralPaddings={'10px'} maxWidth={'50%'} smallMarginBottom={'10px'}>
						<Input name="fname" type="text" onChange={this.handleContactFormEdit} placeholder={wantMore.form.firstField.placeholder} minLength="4" shadow autoComplete="off" required="required" />
					</Column>
					<MediaQuery maxWidth={theme.breakPoints.xsmall}>
						<Row />
					</MediaQuery>
					<Column lateralPaddings={'10px'} maxWidth={'50%'} smallMarginBottom={'10px'}>
						<Input name="lname" type="text" onChange={this.handleContactFormEdit} placeholder={wantMore.form.secondField.placeholder} minLength="4" shadow autoComplete="off" required="required" />
					</Column>
				</Row>
				<Row>
					<Column lateralPaddings={'10px'} smallMarginBottom={'10px'}>
						<Input name="email" type="email" onChange={this.handleContactFormEdit} placeholder={wantMore.form.thirdField.placeholder} shadow autoComplete="off" required="required" />
					</Column>
				</Row>
				<Row>
					<Column>
						<BtnField type="submit" style={{padding: 0}} marginLeft={'auto'} marginRight={'auto'} maxWidth={'175px'} hoverBg={theme.colors.salomie}>{wantMore.form.submit}</BtnField>
						<Paragraph color={theme.colors.white} fontSize={'13px'} textAlign={'center'}>{wantMore.form.submitLabel}</Paragraph>
					</Column>
				</Row>
			</form>
		)
	}
}

export default ContactForm
