import styled from 'styled-components'

import theme from '../../../theme/theme'

export const FormContainer = styled.div`
	padding-top: 1.5em;
	padding-left: 0.5em;
	padding-right: 0.5em;
`

export const ListField = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
`

export const ListItem = styled.li`
	border-bottom: 1px solid ${theme.colors.silver};
	cursor: pointer;
	font-family: ${theme.fonts.primary};
	font-size: 21px;
	margin-bottom: 0;
	padding: 0.5em 0;
	transition: all 0.4s;

	&.selected {
		background-color: ${theme.colors.gallery};
	}

	&:hover,
	&:focus {
		background-color: ${theme.colors.gallery};
	}
`