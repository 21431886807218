import styled from 'styled-components'

import theme from '../../../theme/theme'

export const CardMask = styled.div`
`

export const CardContainer = styled.div`
    background-color: ${theme.colors.white};
    box-shadow: 0 6px 8px 0 rgba(53, 53, 53, 0.17);
    box-sizing: border-box;
    max-height: 580px;
    border-radius: 8px;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
        background: ${theme.colors.gallery}; 
    }

    &::-webkit-scrollbar-thumb {
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
        background: ${theme.colors.crusta}; 
        transition: all 0.4s;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: ${theme.colors.salomie}; 
    }
`