import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import MediaQuery from 'react-responsive'
import { CardElement, injectStripe } from 'react-stripe-elements'

import { InputStyle as InputContainer } from '../InputField/inputFieldStyles'

import {
	Row,
	Column,
} from '../../layouts'

import {
	Button,
	BtnField,
	LoadingData,
	Paragraph,
	Input,
	Link
} from '../'

import {
	charge, getCoupon, clearCoupon, requestCheck, newDealAlert
} from '../../../factory'

import theme from '../../../theme/theme'

// TODO: Specific error for page
class CheckoutForm extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			loading: false,
			error: '',
			coupon: '',
			email: '',
			fullname: '',
			check: false

		}

		this.onChange = this.onChange.bind(this)
		this.onSuccess = this.onSuccess.bind(this)
		this.onError = this.onError.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
		this.validation = this.validation.bind(this)
	}

	componentDidMount() {
		this.props.clearCoupon()
	}

	renderCoupon() {
		if (this.props.couponValid) {
			return (
				<Paragraph style={{ marginBottom: 0, fontSize: 14 }}>{this.props.coupon.name}</Paragraph>
			)
		}
		return (
			<Paragraph style={{ marginBottom: 0, fontSize: 14 }}>{this.props.couponError}</Paragraph>
		)
	}

	onSuccess() {
		try {
			/* eslint-disable-next-line */
			analytics.track(`Checkout Form Success`)
		} catch (e) {
			// ignore
		}

		const state = Object.assign({}, this.state)
		delete state.price
		delete state.position
		delete state.loading
		delete state.error
		this.setState({ loading: false })


		window.location = '/thankyou'
	}

	onError() {
		try {
			/* eslint-disable-next-line */
			analytics.track(`Checkout Form Error`)
		} catch (err) {
			// ignore
		}

		this.setState({ error: "There was an issue processing your card. Please try again.", loading: false })
	}

	onChange(e) {
		if (e.target.name.includes('phone')) {
			this.setState({ [e.target.name]: e.target.value.replace(/-/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") })
		} else {
			this.setState({ [e.target.name]: e.target.value })
		}

		if (e.target.name.includes('coupon') && e.target.value.length > 3) {
			clearTimeout(this.state.timeout)
			const timeout = setTimeout(() => this.props.getCoupon(this.state.coupon), 500)
			this.setState({ timeout })
		}
	}

	handleSubmit(ev) {
		// We don't want to let default form submission happen here, which would refresh the page.
		ev.preventDefault()

		this.setState({ error: '' })

		try {
			/* eslint-disable-next-line */
			analytics.track(`Checkout Form Submitted`)
		} catch (e) {
			// ignore
		}

		if (!this.validation() ) {
			return
		}

		const { fullname, email } = this.state

		try {
			/* eslint-disable-next-line */
			const UUID = analytics.user().anonymousId()
			/* eslint-disable-next-line */
			analytics.identify(UUID, {
				fullname,
				email,
			})
		} catch (e) {
			// ignore
		}

		this.setState({ loading: true, error: '' })

		if (this.state.check) {
			this.props.requestCheck({fullname,
				plans: this.props.products,
				bill: this.props.bill,
				email: email,
				discount: this.props.discount,
				coupon: this.props.couponValid ? this.state.coupon : '',
				callBack: this.onSuccess
			})
		} else {
			// Within the context of `Elements`, this call to createToken knows which Element to
			// tokenize, since there's only one in this group.
			this.props.stripe.createToken({ name: fullname })
				.then(({ token }) => {
					const state = Object.assign({}, this.state)
					delete state.price
					delete state.position
					delete state.loading
					delete state.error
					delete state.coupon

					this.props.charge({
						fullname,
						plans: this.props.products,
						bill: this.props.bill,
						email: email,
						token,
						plan: this.props.plan,
						coupon: this.props.couponValid ? this.state.coupon : '',
						metadata: state,
						callBack: this.onSuccess,
						callBackError: this.onError })
				})

			this.props.newDealAlert({fullname,
				plans: this.props.products,
				bill: this.props.bill,
				email: email,
				discount: this.props.discount,
				coupon: this.props.couponValid ? this.state.coupon : '',
			})
		}
	}

	validation() {
		const { fullname, email } = this.state
			const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line
		if (!fullname) {
			this.setState({
				error: 'Please enter the name as it appears on your card',
			})
			return false
		} else if (!email || !emailRegex.test(email)) {
			this.setState({
				error: 'Please enter a valid email.',
			})

			return false
		}

		this.setState({
			error: '',
		})

		return true
	}


	render() {
		const { prevPosition } = this.props
		const { fullname, error, loading, email, coupon} = this.state

		return (
			<div maxWidth={'840px'} style={{width: '100%'}}>
				<Row>
					<Column lateralPaddings={'10px'} maxWidth={'50%'}>
						<Paragraph style={{ marginBottom: 0 }}>Full Name</Paragraph>
						<Input value={fullname} name="fullname" type="text" onChange={this.onChange}  minLength="4" shadow autoComplete="off" required="required" />
					</Column>
					<MediaQuery maxWidth={theme.breakPoints.xsmall}>
						<Row />
					</MediaQuery>
					<Column lateralPaddings={'10px'} maxWidth={'50%'}>
						<Paragraph style={{ marginBottom: 0 }}>Email</Paragraph>
						<Input value={email} name="email" type="text" onChange={this.onChange}  minLength="4" shadow autoComplete="off" required="required" />
					</Column>
				</Row>
				<Row>
					<Column lateralPaddings={'10px'} maxWidth={'50%'}>
						<Row style={{flexDirection: 'row', marginBottom: 0, marginLeft: 0}}>
							<Paragraph style={{ marginBottom: 0 }}>Coupon Code (Optional)</Paragraph>
							{(this.props.couponPending) && <LoadingData style={{display: 'inline', width: '25%', background: 'transparent'}} maxWidth={'25px'} padding={15}/>}
						</Row>
						<Input value={coupon} name="coupon" type="text" onChange={this.onChange}  minLength="4" shadow autoComplete="off" required="required" />
						{(Object.keys(coupon).length !== 0 && !this.props.couponValid && !this.props.couponPending) &&
							<Paragraph fontSize={'12px'} fontWeight={400} style={{height: '1.5em', marginTop: 5, color: theme.colors.crusta}}>Coupon code is not valid</Paragraph>
						}
					</Column>
					<MediaQuery maxWidth={theme.breakPoints.xsmall}>
						<Row />
					</MediaQuery>
					<Column lateralPaddings={'10px'} maxWidth={'100%'}>
						{!this.state.check ?
							<div>
								<Paragraph style={{ marginBottom: 0 }}>Payment Information</Paragraph>
								<InputContainer shadow style={{ padding: '0.8em 0.8em' }}>
									<CardElement/>
								</InputContainer>
							</div>
							: <div>
								<Row style={{marginLeft: 0}}>
									<Paragraph style={{marginBottm: 0, flex: 2}}>Please mail checks to: </Paragraph>
									<Column style={{marginTop: 0, flex: 2}}>
										<Paragraph style={{margin: 0, lineHeight: '1em'}}>7700 Northcross Dr.</Paragraph>
										<Paragraph style={{margin: 0, lineHeight: '1em'}}>#10251</Paragraph>
										<Paragraph style={{margin: 0, lineHeight: '1em'}}>Austin, TX 78731</Paragraph>
									</Column>
								</Row>
							</div>
						}
						<Link onClick={()=>this.setState({check: !this.state.check})} style={{height: '1.5em', fontWeight: 400, marginTop: 5, fontFamily: theme.fonts.primary, fontSize: 12, color: theme.colors.crusta}}>
							Want to pay by {this.state.check ? 'credit card' : 'check' }?</Link>
					</Column>
				</Row>
				{error &&
                <Row style={{ textAlign: 'center', margin: 0 }}>
                	<Paragraph style={{ margin: 'auto', color: theme.colors.crusta, fontSize: 16, textAlign: 'center', marginBottm: 0 }}>{error}</Paragraph>
                </Row>
				}
				<Row>
					{loading ?
						<Column maxWidth={'100%'} style={{ margin: '10px 0px' }}>
							<LoadingData/>
						</Column>
						:
						<Column>
							<BtnField disabled={!(coupon === '' || this.props.couponValid)} onClick={this.handleSubmit} marginLeft={'auto'} marginRight={'auto'} maxWidth={'150px'} style={{marginTop: 20}} hoverBg={theme.colors.salomie}>Submit</BtnField>
							<Button onClick={prevPosition} style={{margin: 'auto', marginBottom: 10, background: 'white', border: `2px solid ${theme.colors.sunglow}`, width: 150}} maxWidth={'150px'} hoverBg={theme.colors.salomie}>Back</Button>
						</Column>
					}
				</Row>
			</div>
		)
	}
}

CheckoutForm.propTypes = {
	error: PropTypes.any,
	onChange: PropTypes.func,
	prevPosition: PropTypes.func,
	handleSubmit: PropTypes.func,
	clearErrors: PropTypes.func,
	submitOrgRequest: PropTypes.func,
	loading: PropTypes.func,
	success: PropTypes.bool,
	stripe: PropTypes.object,
	charge: PropTypes.func,
	coupon: PropTypes.object,
	couponValid: PropTypes.bool,
	getCoupon: PropTypes.func,
	couponError: PropTypes.string,
	clearCoupon: PropTypes.func,
	values: PropTypes.object,
	plan: PropTypes.array,
	bill: PropTypes.string,
	discount: PropTypes.string,
	requestCheck: PropTypes.func,
	products: PropTypes.array,
	newDealAlert: PropTypes.func,
	pending: PropTypes.bool,
	couponPending: PropTypes.bool
}

const mapStateToProps = (state) => {
	const { utilState } = state
	return {
		error: utilState.error,
		pending: utilState.pending,
		success: utilState.success,
		coupon: utilState.coupon,
		couponValid: utilState.couponValid,
		couponError: utilState.couponError,
		couponPending: utilState.couponPending
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		charge,
		getCoupon: (coupon) => dispatch(getCoupon(coupon)),
		clearCoupon: () => dispatch(clearCoupon()),
		requestCheck: (data) => dispatch(requestCheck(data)),
		newDealAlert: (data) => dispatch(newDealAlert(data))
	}
}

export default injectStripe(connect(
	mapStateToProps,
	mapDispatchToProps
)(CheckoutForm))
