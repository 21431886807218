export default function checkMobileBrowser() {
	var isMobile = false // initiate as false
	if (typeof window === 'undefined') {
		return isMobile
	}

	// device detection
	if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
		isMobile = true
	}
	return isMobile
}
