import React from 'react'
import PropTypes from 'prop-types'
import MediaQuery from 'react-responsive'

import theme from '../../../theme/theme'

import {
	Button,
} from './burgerBtnStyles'

class BurgerBtn extends React.Component {
	constructor (props) {
		super(props)

		this.state = {
			toggle: true
		}

		this.toggleState = this.toggleState.bind(this)
	}

	toggleState () {
		this.props.toggleMenu(!this.state.toggle)
		this.setState({ toggle: !this.state.toggle })
	}

	render () {
		return (
			<MediaQuery maxWidth={theme.breakPoints.small}>
				<Button onClick={this.toggleState.bind(this)}>
					{this.state.toggle ? <i className="fa fa-bars"></i> : <i className="fa fa-times"></i>}
				</Button>
			</MediaQuery>
		)
	}
}

BurgerBtn.propTypes = {
	toggleMenu: PropTypes.func
}

export default BurgerBtn