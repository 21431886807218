import styled from 'styled-components'

import theme from '../../../theme/theme'

export const CheckContainer = styled.div`
	margin-bottom: 1.5em;
	overflow: hidden;
	padding: 10px;
`

export const CheckLabel = styled.label`
	cursor: pointer;
	display: inline-block;
	font-family: ${theme.fonts.primary};
	line-height: 25px;
	position: relative;
	transition: all 0.4s;
	vertical-align: middle;

	&:before {
		background-color: ${theme.colors.sunglow};
		border-radius: 4px;
		content: '';
		display: inline-block;
		height: 25px;
		margin-right: 1em;
		vertical-align: middle;
		width: 25px;
	}

	input:checked + &:after {
		border: solid white;
		border-width: 0 3px 3px 0;
		content: '';
		left: 9px;
		height: 10px;
		padding: 0 4px 0 0;
		position: absolute;
		transform: rotate(45deg);
		top: 8px;
		width: 5px;
	}
`

export const Check = styled.input.attrs({
	checked: props => props.checked ? 'checked' : '',
	type: 'radio',
})`
	left: -99999px;
	position: relative;
	visibility: hidden;
`
