import styled from 'styled-components'

import theme from '../../../theme/theme'

export const Title = styled.h5`
    font-family: ${theme.fonts.secondary};
    font-size: 24px;
    font-weight: 900;
    margin-bottom: 0.5em;
`

export const Label = styled.p`
    font-family: ${theme.fonts.primary};
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
`

export const Text = styled.p`
    font-family: ${theme.fonts.primary};
    font-size: 16px;
    margin-bottom: 1em;

    a {
        color: ${theme.colors.black};
        text-decoration: none;

        &:hover,
        &:focus {
            color: ${theme.colors.crusta};
        }
    }
`