import colors from '../colors'

export default {
	bg: {
		color: colors.wewak,
	},
	content: {
		title: "Leave Your Impact",
		paragraph: "With Flourish, it's easy to make giving a part of your life. Together we can give more than ever.",
	},
	features: {
		first: {
			icon: 'https://flourishfiles.blob.core.windows.net/files/Marketing/Icons/footprint.png',
			title: "Flourish Footprint",
			paragraph: "See the projects, causes, and geographies your dollars have reached. Make your change.",
		},
		second: {
			icon: 'https://flourishfiles.blob.core.windows.net/files/Marketing/Icons/relationships.png',
			title: "Build Relationships",
			paragraph: "Give your feedback and keep up with your nonprofits through surveys and news updates.",
		},
		third: {
			icon: 'https://flourishfiles.blob.core.windows.net/files/Marketing/Icons/pie.png',
			title: "Next Level Accountability",
			paragraph: "Keep your nonprofits accountable by giving to specific projects and seeing where every penny goes.",
		},
		fourth: {
			betaLabel: 'https://flourishfiles.blob.core.windows.net/files/Marketing/Icons/Beta_tag.png',
			icon: 'https://flourishfiles.blob.core.windows.net/files/Marketing/Icons/make_social.png',
			title: "Make It Social",
			paragraph: "Let your friends and followers know how you’ve helped by sharing on Instagram and Facebook.",
		},
	}
}
