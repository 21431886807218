import colors from '../colors'

export default {
	bg: {
		color: colors.shadowGreen
	},
	content: {
		title: "Step 4. Retarget",
		paragraph: "It takes over 90 hours of time with someone before you consider them a real friends",
  	subtext: "Relationships take work. Utilizing our tools, products, and expertise, we help you to get to know your donors. But it's crucial that you continue to engage your current and prospective donors to maximize their affinity for your cause, thereby maximizing their lifetime value."
	},
}
