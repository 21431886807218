export default {
	salomie: '#FEDF90',
	sunglow: '#ffcc33',
	crusta: '#f7772f',
	westCoast: '#525d17',
	wewak: '#f5a3a4',
	shadowGreen: '#91c6c1',
	porcelain: '#f2f5f5',
	white: '#ffffff',
	gallery: '#EEEEEE',
	alto: '#DDDDDD',
	silver: '#c0c0c0',
	dustyGray: '#9B9B9B',
	mineShaft: '#222222',
	red: '#CC0000',
}