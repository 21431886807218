import React from 'react'
import PropTypes from 'prop-types'

import {
	Container,
	Select,
	Icon,
} from './selectFieldStyles'

class SelectField extends React.Component {
	constructor (props) {
		super (props)
	}

	render () {
		return (
			<Container>
				<Select nameField={this.props.name} onChange={this.props.onChange} required={this.props.required} defaultValue="0">
					<option value="0">{this.props.label}</option>
					{this.props.list && this.props.list.map((item, i) =>
						<option key={i} value={item.value}>{item.name}</option>
					)}
				</Select>
				<Icon className="fa fa-sort-down"></Icon>
			</Container>
		)
	}
}

SelectField.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
	list: PropTypes.array,
	required: PropTypes.string,
	onChange: PropTypes.func,
}

export default SelectField
