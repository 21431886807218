import colors from '../colors'

export default {
	content: {
		title: "Step 3. Fundraise",
		paragraph: "The average person spends over 4 hours a day on their phone",
		listTitle: 'How It Works',
  	list: [
  		'Harness your fans’ enthusiasm',
  		'Donors link bank accounts',
			"Allow donors to select a project to specify impact",
  		'Begin to Flourish with donors giving about $25/month'
  	]
	},
}
