import styled from 'styled-components'

import {
	CardNumberElement,
	CardExpiryElement,
	CardCVCElement,
	PostalCodeElement,
} from 'react-stripe-elements'

import theme from '../../../theme/theme'

export const FormContainer = styled.div`
	padding-top: 1.5em;
	padding-left: 0.5em;
	padding-right: 0.5em;
`

export const CardNum = styled(CardNumberElement)`
	background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.dustyGray};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    height: 61px;
    justify-content: center;
    margin-bottom: 1em;
    padding: 0.5em 1em;
`

export const CardExp = styled(CardExpiryElement)`
	background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.dustyGray};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    height: 61px;
    justify-content: center;
    margin-bottom: 1em;
    padding: 0.5em 1em;
`

export const CardCvc = styled(CardCVCElement)`
	background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.dustyGray};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    height: 61px;
    justify-content: center;
    margin-bottom: 1em;
    padding: 0.5em 1em;
`

// export const ZipCode = styled(PostalCodeElement)`
// 	background-color: ${theme.colors.white};
//     border: 1px solid ${theme.colors.dustyGray};
//     border-radius: 4px;
//     display: flex;
//     flex-direction: column;
//     height: 61px;
//     justify-content: center;
//     margin-bottom: 1em;
//     padding: 0.5em 1em;
// `
