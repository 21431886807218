import React from 'react'
import PropTypes from 'prop-types'

import {
	Container,
	Row,
	Column,
} from '../../layouts'

import {
	Title,
	LoadingData,
	Paragraph,
	Image
} from '../'

import  {
	FormContainer,
	SearchField,
	SearchIcon,
	SearchInput,
	ListField,
	ListItem,
} from './organizationsFormStyles'
import theme from '../../../theme/theme'

class OrganizationsForm extends React.Component {
	constructor(props) {
		super(props)

		this.title = this.props.title

		this.selectOption = this.selectOption.bind(this)
	}

	selectOption(org) {
		this.props.onSelectOrg(org)
	}

	renderSearchedOrgs() {
		if (this.props.matchingOrgs.length > 0) {
			return (
				<ListField>
					{this.props.matchingOrgs.map((item, i) =>
						<ListItem key={i} className={(this.props.selectedOrg && this.props.selectedOrg.id === item.id) ? 'selected' : ''} onClick={this.selectOption.bind(this, item)}>{item.title}</ListItem>
					)}
				</ListField>
			)
		}
		return (
			<div style={{justifyContent: 'center', width: '100%', flexDirection: 'column'}}>
				<Image src={theme.sections.intro.content.secondPart.columns.first.image} marginBottom={'1em'} maxWidth={'125px'} maxHeight={"200px"} />
				<Title fontSize={'25px'} style={{margin: 'auto'}}> Oops, no organizations match your search. Please revise.</Title>
			</div>
		)
	}

	render() {
		return (
			<FormContainer style={{maxWidth: '90%', margin: 'auto'}}>
				<Container>
					<Row>
						<Column maxWidth={'60%'} style={{paddingLeft: 0}}>
							{this.props.errors && <Paragraph color={theme.colors.red}>{this.props.errors}</Paragraph>}
							<Title fontSize={'33px'} marginLeft={'0'} marginTop={'0.2em'}>{this.title}</Title>
						</Column>
						<Column maxWidth={'40%'} style={{paddingLeft: 0}}>
							<SearchField>
								<SearchIcon className={'fa fa-search'} />
								<SearchInput onChange={this.props.onChangeSearch} value={this.props.searchTerm}/>
							</SearchField>
						</Column>
					</Row>
					<Row style={{paddingLeft: 0}}>
						<Column style={{paddingLeft: 0}}>
							{this.props.searching ?
								 this.renderSearchedOrgs() :
								(this.props.orgs && this.props.orgs.length > 0 ?
									<ListField>
										{this.props.orgs.map((item, i) =>
											<ListItem key={i} className={(this.props.selectedOrg && this.props.selectedOrg.id === item.id) ? 'selected' : ''} onClick={this.selectOption.bind(this, item)}>{item.title}</ListItem>
										)}
									</ListField> :
									<LoadingData />
								)
							}
						</Column>
					</Row>
				</Container>
			</FormContainer>
		)
	}
}

OrganizationsForm.propTypes = {
	title: PropTypes.string,
	orgs: PropTypes.object,
	matchingOrgs: PropTypes.array,
	selectedOrg: PropTypes.object,
	searchOrgs: PropTypes.func,
	onSelectOrg: PropTypes.func,
	next: PropTypes.func,
	errors: PropTypes.array,
	onChangeSearch: PropTypes.func,
	searching: PropTypes.bool,
	searchTerm: PropTypes.string
}

export default OrganizationsForm
