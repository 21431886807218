import React from 'react'
import PropTypes from 'prop-types'

import {
	CardMask,
	CardContainer,
} from './cardFormStyles'

class CardForm extends React.Component {
	render() {
		return (
			<CardMask>
				<CardContainer style={this.props.style}>
					{this.props.children}
				</CardContainer>
			</CardMask>
		)
	}
}

CardForm.propTypes = {
	children: PropTypes.array,
	style: PropTypes.object
}

export default CardForm
