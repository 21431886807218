import styled from 'styled-components'
import { Paragraph } from '../'
import theme from '../../../theme/theme'

export const FormContainer = styled.div`
	padding-top: 1.5em;
	padding-left: 0.5em;
	padding-right: 0.5em;
`

export const P = styled(Paragraph)`
	margin-bottom: 0px;
	color: ${theme.colors.mineShaft};
`
