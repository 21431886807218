import React from 'react'
import PropTypes from 'prop-types'
import MediaQuery from 'react-responsive'

import {
	Container,
	Row,
	Column,
} from '../../layouts'

import {
	Title,
	Subtitle,
	Button,
	Input
} from '../'

import {
	FormContainer,
	P as Paragraph
} from './AdminFormStyles'

import theme from '../../../theme/theme'

class OneTimeDonate extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		const { values } = this.props

		return (
			<FormContainer>
				<Container>
					<Row>
						<Column>
							<Title fontSize={'33px'} marginLeft={'0'}>{this.props.title}</Title>
							<Subtitle fontSize={'21px'} marginLeft={'0'}>{this.props.subtitle}</Subtitle>
						</Column>
					</Row>
					<Row>
						<Column lateralPaddings={'10px'} maxWidth={'50%'}>
							<Paragraph>First Name</Paragraph>
							<Input value={values.f_name} name="f_name" type="text" onChange={this.props.handleFormEdit}  minLength="4" shadow autoComplete="off" required="required" />
						</Column>
						<MediaQuery maxWidth={theme.breakPoints.xsmall}>
							<Row />
						</MediaQuery>
						<Column lateralPaddings={'10px'} maxWidth={'50%'}>
							<Paragraph>Last Name</Paragraph>
							<Input value={values.l_name} name="l_name" type="text" onChange={this.props.handleFormEdit}  minLength="4" shadow autoComplete="off" required="required" />
						</Column>
					</Row>
					<Row>
						<Column lateralPaddings={'10px'} maxWidth={'50%'}>
							<Paragraph>Phone</Paragraph>
							<Input maxLength={12} value={values.phone} placeholder="214-555-5555" name="phone" type="phone" onChange={this.props.handleFormEdit}  minLength="4" shadow autoComplete="off" required="required" />
						</Column>
						<MediaQuery maxWidth={theme.breakPoints.xsmall}>
							<Row />
						</MediaQuery>
						<Column lateralPaddings={'10px'} maxWidth={'50%'}>
							<Paragraph>Email (Your Username)</Paragraph>
							<Input value={values.email} name="email" type="text" onChange={this.props.handleFormEdit}  minLength="4" shadow autoComplete="off" required="required" />
						</Column>
					</Row>
					<Row>
						<Column>
							{this.props.errors && <Subtitle color="red" fontSize={'21px'} marginLeft={'0'}>{this.props.errors}</Subtitle>}
						</Column>
					</Row>
					<Row>
						<Column>
							<Button onClick={this.props.onBack} marginLeft={'auto'} marginRight={'auto'} maxWidth={'150px'} hoverBg={theme.colors.salomie}>Back</Button>
						</Column>
						<Column>
							<Button onClick={this.props.onSubmit} marginLeft={'auto'} marginRight={'auto'} maxWidth={'150px'} hoverBg={theme.colors.salomie}>Next</Button>
						</Column>
					</Row>
				</Container>
			</FormContainer>
		)
	}
}

OneTimeDonate.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	values: PropTypes.object,
	onChange: PropTypes.func,
	isCustom: PropTypes.bool,
	submitBtn: PropTypes.string,
	errors: PropTypes.any,
	valid: PropTypes.bool,
	onSubmit: PropTypes.func,
	loading: PropTypes.bool,
	handleFormEdit: PropTypes.func,
	onBack: PropTypes.func
}

OneTimeDonate.defaultProps = {
	values: {
		fname: '',
		lname: '',
		phone: '',
		email: ''
	}
}

export default OneTimeDonate
