import styled from 'styled-components'

import theme from '../../../theme/theme'

export const Container = styled.div`
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.dustyGray};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    height: ${props => props.height ? props.height : '61px'};
    justify-content: space-between;
    margin-bottom: 1em;
    padding: 0.5em 1em;
`

export const InputStyle = styled.div`
    appearance: none;
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.dustyGray};
    border-radius: 5px;
    ${props => props.shadow ? 'box-shadow: 0 4px 7px 0 rgba(120, 120, 120, 0.3);' : ''}
    font-family: ${theme.fonts.primary};
    font-size: 16px;
    height: 44px;
    outline: none;
    padding: 0.5em 0.8em;
`

export const Label = styled.small`
    color: ${theme.colors.dustyGray};
    font-family: ${theme.fonts.primary};
    font-size: 12px;
    line-height: 12px;
`

export const Group = styled.div`
    display: flex;
    flex-direction: row;
`

export const Input = styled.input.attrs({
	name: props => props.nameField,
	type: props => props.fieldType && props.fieldType == 'password' ? (props.viewPass ? props.fieldType : 'text') : props.fieldType,
	required: props => props.required
})`
    appearance: none;
    background: transparent;
    border: none;
    font-family: ${theme.fonts.primary};
    font-size: 16px;
    outline: none;
    width: 100%;

    &:focus {
        outline: none;
    }
`

export const EyeIcon = styled.i`
    color: ${theme.colors.dustyGray};
	cursor: pointer;
`
