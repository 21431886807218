import styled from 'styled-components'

import theme from '../../../theme/theme'

import { responsiveFontSize } from '../../../util'

const BtnField = styled.button.attrs({
	disabled: props => props.disabledBtn !== undefined && !props.disabledBtn ? 'disabled' : '',
})`
    background-color: ${props => props.bgColor ? props.bgColor : theme.colors.buttons};
    border: ${props => props.borderColor ? `1px solid ${props.borderColor}` : 'none'};
    border-radius: 1.5rem;
    box-shadow: 0 15px 35px 0 ${theme.colors.buttonsShadow};
    color: ${theme.colors.black};
    cursor: pointer;
    font-family: ${theme.fonts.primary};
    font-size: ${props => props.fontSize ? props.fontSize : '16px'};
    font-weight: 600;
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : '1em'};
    max-height: 42px;
    margin-left: ${props => props.marginLeft ? props.marginLeft : '0'};
    margin-right: ${props => props.marginRight ? props.marginRight : '0'};
    max-width: ${props => props.maxWidth ? props.maxWidth : 'unset'};
    outline: none;
    transition: 0.4s;
    text-align: center;
    width: 100%;

    &:hover,
    &:focus {
        background-color: ${props => props.hoverBg ? props.hoverBg : theme.colors.buttonsHovered};
        color: ${props => props.hoverTextColor ? props.hoverTextColor : 'initial'};
    }

    &:disabled {
        background-color: ${props => props.hoverBg ? props.hoverBg : theme.colors.buttonsHovered};
        color: ${props => props.hoverTextColor ? props.hoverTextColor : 'initial'};
        cursor: not-allowed;
    }

    @media (max-width: ${theme.breakPoints.small}) {
        font-size: ${props => props.fontSize ? responsiveFontSize(props.fontSize, 20) : responsiveFontSize('16px', 10)};
    }
`

export default BtnField
