export default {
	bg: {
		color: false,
		image: 'https://flourishfiles.blob.core.windows.net/files/Marketing/LogoPNGs/RGB/Logomark/logo_opacity.png',
		size: '750px 750px',
		position: '-190px -375px',
	},
	content: {
		firstPart: {
			title: "Turning pocket change into global change.",
			paragraph: "Give to your cause every time you swipe your debit or credit card and watch your donations make an impact. Try it for yourself!",
			labelBtn: "Are you a nonprofit?",
			btn: {
				text: "Learn More",
				link: '/nonprofits',
			},
			image: 'https://storage.googleapis.com/resources.flourishchange.com/Marketing/Website/appHeader.png',
		},
		secondPart: {
			title: "Welcome to Micro-Donating",
			paragraph: "Every purchase you make connects to a bigger story — one that supports the causes that you are passionate about.",
			columns: {
				first: {
					image: 'https://flourishfiles.blob.core.windows.net/files/Marketing/Illustrations/PNGs/Onboarding1.png',
					title: "Pick your cause",
					paragraph: "Choose from a growing list of organizations and causes.",
				},
				second: {
					image: 'https://flourishfiles.blob.core.windows.net/files/Marketing/Illustrations/PNGs/Onboarding2.png',
					title: "Give a little",
					paragraph: "Link your bank account or credit card and set your own donation limits.",
				},
				third: {
					image: 'https://flourishfiles.blob.core.windows.net/files/Marketing/Illustrations/PNGs/Onboarding3.png',
					title: "Grow your impact",
					paragraph: "Giving just a little each day makes a huge difference for your cause.",
				}
			}
		}
	}
}
