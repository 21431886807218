// MyStoreCheckout.js
import React from 'react'
import { Elements, StripeProvider } from 'react-stripe-elements'

import CheckoutForm from './CheckoutForm'

import { DONATE_STRIPEKEY } from '../../../theme/actionTypes'

class MyDonateForm extends React.Component {
	constructor() {
		super()
		this.state = {stripe: null}
	}
	componentDidMount() {
		if (window.Stripe) {
			// eslint-disable-next-line
			this.setState({stripe: window.Stripe(DONATE_STRIPEKEY)})
		} else {
			document.querySelector('#stripe-js').addEventListener('load', () => {
				// Create Stripe instance once Stripe.js loads
				this.setState({stripe: window.Stripe(DONATE_STRIPEKEY)})
			})
		}
	}

	render() {
		return (
			<StripeProvider stripe={this.state.stripe}>
				<Elements>
					<CheckoutForm {...this.props}/>
				</Elements>
			</StripeProvider>
		)
	}
}

export default MyDonateForm
