import styled, { keyframes } from 'styled-components'

import theme from '../../../theme/theme'

const overlayAnimation = keyframes`
    0% {
        background-color: rgba(52, 59, 64, 0);
        z-index: -1;
    }
    1% {
        z-index: 10;
    }
    100% {
        background-color: rgba(52, 59, 64, 0.9);
        z-index: 10;
    }
`

const overlayAnimationReverse = keyframes`
    0% {
        background-color: rgba(52, 59, 64, 0.9);
        z-index: 10;
    }
    99% {
        z-index: 10;
    }
    100% {
        background-color: rgba(52, 59, 64, 0);
        z-index: -1;
    }
`

const menuOpened = keyframes`
    0% {
        left: -260px;
    }
    100% {
        left: 0;
    }
`

const menuOpenedReverse = keyframes`
    0% {
        left: 0;
    }
    100% {
        left: -260px;
    }
`

export const Overlay = styled.div`
    background-color: rgba(52, 59, 64);
    height: 100vh;
    left: 0;
    max-height: 100%;
    max-width: 100%;
    opacity: ${props => props.firstLoad ? '0' : '1'};
    position: fixed;
    top: 0;
    transform: translateZ(0);
    transition: all 0.4s;
    width: 100vw;
    z-index: 10;

    &.opened {
        animation: ${overlayAnimation} 0.4s linear 0s 1 normal forwards;
    }

    &.closed {
        animation: ${overlayAnimationReverse} 0.4s linear 0s 1 normal forwards;
    }
`

export const Menu = styled.div`
    background-color: rgba(52, 59, 64, 0.9);
    height: 100vh;
    left: -260px;
    max-height: 100%;
    max-width: 260px;
    position: relative;
    width: 100vw;
    padding-bottom: 10px;

    &.opened {
        animation: ${menuOpened} 0.4s linear 0s 1 normal forwards;
    }

    &.closed {
        animation: ${menuOpenedReverse} 0.4s linear 0s 1 normal forwards;
    }
`

export const List = styled.ul`
    list-style: none;
    margin: 0;
    padding: 2em 1em;
`

export const Item = styled.li`
    margin-bottom: 1.5em;
`

export const Link = styled.a`
    color: ${theme.colors.white};
    font-family: ${theme.fonts.primary};
    font-size: 16px;
    font-weight: 200;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }
`
