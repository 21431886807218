import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import {
	Container,
	Row,
	Column,
} from '../../layouts'

import {
	Title,
	Paragraph,
	Button,
	BtnField,
	InputField,
	LoadingData,
} from '../'

import {
	FormContainer,
	CardNum,
	CardCvc,
	CardExp,
	ZipCode
} from './generalFormStyles'

import theme from '../../../theme/theme'

class GeneralForm extends React.Component {
	constructor (props) {
		super(props)

		this.state = {
			name: false,
			email: false,
			donation: false,
			cardNumber: false,
			cardCvc: false,
			postalCode: false,
			cardExpiry: false,
		}

		this.onChange = this.onChange.bind(this)
	}

	onChange (e) {
		if (e.target) {
			this.setState({
				[e.target.name]: e.target.value !== "",
			})
		} else {
			this.setState({
				[e.elementType]: !e.empty,
			})
		}

		this.props.onChange(e)
	}

	render () {
		const {
			validatedName,
			validatedEmail,
			validatedDonation,
			cardNumber,
			cardCvc,
			cardExpiry,
			postalCode,
		} = this.props.fieldErrors

		return (
			<FormContainer>
				<Container>
					<Row>
						<Column maxWidth={'580px'}>
							<Title fontSize={'33px'} marginLeft={'0'}>{this.props.title}</Title>
							<Paragraph fontSize={'21px'}>{this.props.thanks1 + (this.props.selectedProj ? this.props.selectedProj.name + ' project' : (this.props.selectedOrg ? this.props.selectedOrg.title + ' charty' : ''))+ '. ' + this.props.thanks2}</Paragraph>
						</Column>
					</Row>
					<Row>
						<Column maxWidth={'40%'}>
							<InputField name="name" type="text" label="Your Name" onChange={this.onChange} required="required" />
						</Column>
						<Column maxWidth={'35%'}>
							<InputField name="email" type="email" label="Email" onChange={this.onChange} required="required" />
						</Column>
						<Column maxWidth={'25%'}>
							<InputField name="donation" type="number" label="Donation Amount" onChange={this.onChange} min={'0'} required="required" />
						</Column>
					</Row>
					<Row>
						<Column>
							<Title fontSize={'33px'} marginLeft={'0'}>{this.props.nextTitle}</Title>
						</Column>
					</Row>
					<Row marginBottom={'0.5em'}>
						<Column maxWidth={'70%'}>
							<CardNum onChange={this.onChange}
								style={{
									base: {
										fontFamily: theme.fonts.primary,
										fontSize: '16px',
										fontStyle: 'normal'
									}
								}}
							/>
						</Column>
						<Column maxWidth={'30%'}>
							<CardCvc onChange={this.onChange} />
						</Column>
					</Row>
					<Row>
						<Column maxWidth={'50%'}>
							<CardExp onChange={this.onChange} />
						</Column>
					</Row>
					<Row marginBottom={'0.8em'}>
						<Column>
							{this.props.errors && Object.keys(this.props.errors).map((item, i) =>
								<Paragraph color={theme.colors.red} key={i}>{this.props.errors[item]}</Paragraph>
							)}
							{(!validatedName && this.state.name) &&
								<Paragraph color={theme.colors.red}>{this.props.fieldMsg.name}</Paragraph>
							}
							{(!validatedEmail && this.state.email) &&
								<Paragraph color={theme.colors.red}>{this.props.fieldMsg.email}</Paragraph>
							}
							{(!validatedDonation && this.state.donation) &&
								<Paragraph color={theme.colors.red}>{this.props.fieldMsg.donation}</Paragraph>
							}
							{(!cardNumber && this.state.cardNumber) &&
								<Paragraph color={theme.colors.red}>{this.props.fieldMsg.card}</Paragraph>
							}
							{(!cardCvc && this.state.cardCvc) &&
								<Paragraph color={theme.colors.red}>{this.props.fieldMsg.cvc}</Paragraph>
							}
							{(!cardExpiry && this.state.cardExpiry) &&
								<Paragraph color={theme.colors.red}>{this.props.fieldMsg.cardExp}</Paragraph>
							}
							{(!postalCode && this.state.postalCode) &&
								<Paragraph color={theme.colors.red}>{this.props.fieldMsg.zip}</Paragraph>
							}
							{this.props.loadDonate && <LoadingData align={'left'} maxWidth={'30px'} padding={'0.2em 0'} />}
						</Column>
						<Column vAlign={'end'} row>
							<Button bgColor={theme.colors.crusta} fontColor={theme.colors.white} marginRight={'0.5em'} maxWidth={'110px'} onClick={this.props.back}>{'Back'}</Button>
							<BtnField type="submit" marginLeft={'0.5em'} marginBottom={'0'} maxWidth={'120px'} disabledBtn={this.props.disableSubmit}>{'Donate'}</BtnField>
						</Column>
					</Row>
				</Container>
			</FormContainer>
		)
	}
}

GeneralForm.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	nextTitle: PropTypes.string,
	monthsList: PropTypes.array,
	yearsList: PropTypes.array,
	onChange: PropTypes.func,
	thanks1: PropTypes.string,
	thanks2: PropTypes.string,
	selectedOrg: PropTypes.object,
	selectedProj: PropTypes.object,
	disableSubmit: PropTypes.bool,
	back: PropTypes.func,
	fieldMsg: PropTypes.object,
	fieldErrors: PropTypes.object,
	errors: PropTypes.object,
	loadDonate: PropTypes.bool,
}

export default GeneralForm
