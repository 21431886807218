import styled from 'styled-components'

import theme from '../../../theme/theme'

const Section = styled.div`
    background-image: ${props => props.bg && props.bg.image ? 'url(' + props.bg.image + ')' : 'none'};
    background-repeat: ${props => props.bg && props.bg.repeat ? props.bg.repeat : 'no-repeat'};
    background-size: ${props => props.bg && props.bg.size ? props.bg.size : 'auto'};
    background-position: ${props => props.bg && props.bg.position ? props.bg.position : 'center'};
    background-color: ${props => props.bg && props.bg.color ? props.bg.color : theme.colors.white};
    padding-top: ${props => props.paddingTop ? props.paddingTop : 0 };
    padding-bottom: ${props => props.paddingBottom ? props.paddingBottom : 0 };

    @media (max-width: ${theme.breakPoints.small}) {
        ${props => props.smallPaddingTop ? 'padding-top: ' + props.smallPaddingTop + ';' : ''};
        ${props => props.smallPaddingBottom ? 'padding-bottom: ' + props.smallPaddingBottom + ';' : ''};
    }
`

export default Section
