import React from 'react'
import PropTypes from 'prop-types'
import MediaQuery from 'react-responsive'

import {
	Container,
	Row,
	Column,
} from '../../layouts'

import {
	Title,
	Subtitle,
	Button,
	Input,
	Select,
} from '../'

import {
	FormContainer,
	P as Paragraph
} from './NewOrgFormStyles'

import theme from '../../../theme/theme'

class NewOrgForm extends React.Component {
	constructor (props) {
		super(props)

		this.state = {
			mounted: false,
			url: null
		}

	}

	componentDidMount(){
		this.setState({ mounted: true })
	}

	onSuccess = (result) => {
		this.setState({
			url: result.url
		})
		const event = { target:{
			name: 'org_imageURL',
			value: result.url
		} }
		this.props.handleFormEdit(event)
	}

	onError = (error) => {
		this.setState({ error })
	}

	render () {
		const { values } = this.props

		//eslint-disable-next-line
		const client = typeof window !== 'undefined' && filestack.init(process.env.filestackKey)

		return (
			<FormContainer>
				<Container>
					<Row>
						<Column>
							<Title fontSize={'33px'} marginLeft={'0'}>Now, what's your cause?</Title>
							<Subtitle fontSize={'21px'} marginLeft={'0'}>We'll start with the simple stuff...</Subtitle>
						</Column>
					</Row>
					<Row>
						<Column lateralPaddings={'10px'} maxWidth={'50%'}>
							<Paragraph>Organization Title</Paragraph>
							<Input value={values.title} name="org_title" type="text" onChange={this.props.handleFormEdit}  minLength="4" shadow autoComplete="off" required="required" />
						</Column>
						<MediaQuery maxWidth={theme.breakPoints.xsmall}>
							<Row />
						</MediaQuery>
						<Column lateralPaddings={'10px'} maxWidth={'50%'}>
							<Paragraph>Organization Phone</Paragraph>
							<Input value={values.phone} maxLength={12} placeholder="214-555-5555" name="org_phone" onChange={this.props.handleFormEdit}  minLength="4" shadow autoComplete="off" required="required" />
						</Column>
					</Row>
					<Row>
						<Column lateralPaddings={'10px'} maxWidth={'50%'}>
							<Paragraph>Organization  Email</Paragraph>
							<Input value={values.email} name="org_email" type="email" onChange={this.props.handleFormEdit}  minLength="4" shadow autoComplete="off" required="required" />
						</Column>
						<MediaQuery maxWidth={theme.breakPoints.xsmall}>
							<Row />
						</MediaQuery>
						<Column lateralPaddings={'10px'} maxWidth={'50%'}>
							<Paragraph>Organization Website</Paragraph>
							<Input value={values.website} name="org_website" type="text" onChange={this.props.handleFormEdit}  minLength="4" shadow autoComplete="off" required="required" />
						</Column>
					</Row>
					<Row>
						<Column lateralPaddings={'10px'} maxWidth={'50%'}>
							<Paragraph>Organization Category</Paragraph>
							<Select name="org_category_id" type="select" onChange={this.props.handleFormEdit} shadow required="required">
								<option value={values.category_id} disabled selected>-</option>
								{this.props.categories && this.props.categories.map((cat) => <option key={cat.id} value={cat.id}>{cat.title}</option>)}
							</Select>
						</Column>
						<MediaQuery maxWidth={theme.breakPoints.xsmall}>
							<Row />
						</MediaQuery>
						<Column lateralPaddings={'10px'} maxWidth={'50%'}>
							<Paragraph>Organization Logo</Paragraph>
							{this.state.url &&
								<div style={{ border: '2px solid black', height: 125, width: 187.5, marginBottom: 10 }}>
									<img src={this.state.url} style={{ width: '100%', height: '100%' }} />
								</div>
							}
							<input
								type="file"
								accept="image/*"
								onChange={
									(e) => client.upload(e.target.files[0]).then(this.onSuccess)
										.catch(this.onError)
								}
							/>
							{this.state.error &&
								<Paragraph>There was an issue uploading your logo. Please try again.</Paragraph>
							}
						</Column>
					</Row>
					<Row>
						<Column>
							{this.props.errors && <Subtitle color="red" fontSize={'21px'} marginLeft={'0'}>{this.props.errors}</Subtitle>}
						</Column>
					</Row>
					<Row style={{ justifyContent: "center", alignItems: "center" }}>
						<Button onClick={this.props.onBack} marginRight={'20px'} maxWidth={'150px'} hoverBg={theme.colors.salomie}>Back</Button>
						<Button onClick={this.props.onSubmit} maxWidth={'150px'} hoverBg={theme.colors.salomie}>Next</Button>
					</Row>
				</Container>
			</FormContainer>
		)
	}
}

NewOrgForm.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	values: PropTypes.object,
	onChange: PropTypes.func,
	onCustomChange: PropTypes.func,
	isCustom: PropTypes.bool,
	selectedOption: PropTypes.number,
	submitBtn: PropTypes.string,
	errors: PropTypes.any,
	valid: PropTypes.bool,
	submit: PropTypes.func,
	loading: PropTypes.bool,
	onSubmit: PropTypes.func,
	onBack: PropTypes.func,
	handleFormEdit: PropTypes.func,
	categories: PropTypes.array
}

export default NewOrgForm
