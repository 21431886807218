import styled from 'styled-components'

import theme from '../../../theme/theme'

export const Button = styled.button`
    background: transparent;
    border: none;
    color: ${theme.colors.crusta};
    font-size: 24px;
    padding: 0;
    position: fixed;
    right: 1em;
    top: 1em;
    z-index: 11;

    &:focus {
        outline: none;
    }
`
