import React from 'react'
import PropTypes from 'prop-types'

import {
	CheckContainer,
	CheckLabel,
	Check,
} from './checkBoxStyles'

import theme from '../../../theme/theme'

class CheckBox extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<CheckContainer style={this.props.style}>
				<Check type={this.props.type || 'radio'} id={this.props.name + this.props.value} name={this.props.name} checked={this.props.defaultSelected} value={this.props.value} onChange={this.props.onChange} />
				<CheckLabel htmlFor={this.props.name + this.props.value}>
					{this.props.label}
				</CheckLabel>
			</CheckContainer>
		)
	}
}

CheckBox.propTypes = {
	name: PropTypes.string,
	style: PropTypes.object,
	label: PropTypes.string,
	defaultSelected: PropTypes.bool,
	value: PropTypes.number,
	onChange: PropTypes.func,
	type: PropTypes.string
}

export default CheckBox
