import colors from '../colors'

export default {
	bg: {
		color: colors.crusta,
	},
	content: {
		title: "Get Started with Flourish",
		paragraph: "You’ll see how your dollars are being deployed to create an amazing impact.",
		appStore: {
			image: 'https://flourishfiles.blob.core.windows.net/files/Marketing/AppBadges/app-store.svg',
			link: 'https://itunes.apple.com/us/app/flourish-donations/id1356801492?mt=8',
		},
		googlePlay: {
			image: 'https://flourishfiles.blob.core.windows.net/files/Marketing/AppBadges/google-play.png',
			link: 'https://play.google.com/store/apps/details?id=com.flourish.flourish&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
		}
	}
}
