import styled from 'styled-components'

import theme from '../../../theme/theme'

const Container = styled.div`
    margin: 0 auto;
    max-width: ${props => props.maxWidth ? props.maxWidth : '1140px'};
    overflow: hidden;
    padding: 0px 15px;
    max-height: ${props => props.maxHeight ? props.maxHeight : "inherit"}
    align-items: center;
    align-content: center;
    width: 100%;

    @media (max-width: ${theme.breakPoints.large}) {
        max-width: ${props => props.maxWidth && (props.maxWidth === '100%' || props.maxWidth === '100vw') ? props.maxWidth : '1024px'};
    }

    @media (max-width: ${theme.breakPoints.medium}) {
        max-width: ${props => props.maxWidth && (props.maxWidth === '100%' || props.maxWidth === '100vw') ? props.maxWidth : '960px'};
    }

    @media (max-width: ${theme.breakPoints.small}) {
        max-width: ${props => props.maxWidth && (props.maxWidth === '100%' || props.maxWidth === '100vw') ? props.maxWidth : '95%'};
        padding-bottom: 25px;
    }
`

export default Container
