import React from 'react'
import PropTypes from 'prop-types'

import { scrollTo, checkMobileBrowser } from '../../../util'

import theme from '../../../theme/theme'

import {
	Overlay,
	Menu,
	List,
	Item,
	Link,
} from './mobileMenuStyles'

import { DASHBOARD } from '../../../theme/actionTypes'


class MobileMenu extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			firstLoad: true,
		}

		this.linking = this.linking.bind(this)
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({
				firstLoad: false,
			})
		}, 400)
	}

	linking(e) {
		e.preventDefault()

		const link = e.target.getAttribute('href')

		if(link.includes(DASHBOARD)) {
			window.location.href = link
			return
		}

		let mobileHref = ''
		if (checkMobileBrowser) {
			mobileHref = '/m'
		}

		if (link.indexOf('#') !== -1) {
			if (window.location.pathname === '/') {
				const element = document.getElementById(link.substring(2))
				scrollTo(element)
			} else {
				window.location.href = mobileHref + link
			}
		} else {
			window.location.href = mobileHref + link
		}
	}

	render() {
		return (
			<Overlay className={!this.props.toggleMenu ? 'opened' : 'closed' } firstLoad={this.state.firstLoad}>
				<Menu className={!this.props.toggleMenu ? 'opened' : 'closed'}>
					<List>
						{theme.mainMenu && theme.mainMenu.map((item, i) =>
							(<Item key={i}>
								<Link onClick={this.linking} href={item.link}>
									{item.name}
								</Link>
							</Item>)
						)}
					</List>
				</Menu>
			</Overlay>
		)
	}
}

MobileMenu.propTypes = {
	toggleMenu: PropTypes.bool,
}

export default MobileMenu
