import styled from 'styled-components'

import theme from '../../../theme/theme'

const Card = styled.div`
    align-items: center;
    background-color: ${props => props.backgroundColor ? props.backgroundColor : theme.colors.white};
    box-shadow: 0px 0px 30px rgba(73, 78, 92, 0.15);
    display: flex;
    height: ${props => props.height ? props.height : '187px'};
    ${props => props.animatedLeft ? 'left: -105%' : false}
    ${props => props.animatedRight ? 'right: -105%' : false}
    max-width: 540px;
    padding: 40px;
    position: relative;
    transition: all 0.4s;
    word-break: break-word;
    width: 100%;

    &:not(:last-child) {
        margin-bottom: 30px;
    }

    &.animate {
        ${props => props.animatedLeft ? 'left: 0' : false}
        ${props => props.animatedRight ? 'right: 0' : false}
    }

    @media (max-width: ${theme.breakPoints.small}) {
        height: ${props => props.smallHeight ? props.smallHeight + ';' : 'auto'};
        padding: 20px;

        &:last-child {
            margin-bottom: 30px;
        }
    }

@media (max-width: ${theme.breakPoints.xsmall}) {
    height: ${props => props.smallHeight ? props.smallHeight + ';' : 'auto'};
}
`

export default Card
