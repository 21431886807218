import colors from '../colors'

export default {
	bg: {
		color: colors.westCoast,
	},
	content: {
		title: "Security Matters",
		paragraph: "We take your bank privacy and account protection seriously. We use the same banking system as trusted apps such as Venmo, Facebook, and Lyft.",
		btn: {
			text: 'Learn More',
			link: '/security'
		}
	}
}