import colors from '../colors'

export default {
	bg: {
		color: colors.shadowGreen,
	},
	content: {
		title: "Want to Learn More?",
	},
	form: {
		firstField: {
			placeholder: "First Name",
		},
		secondField: {
			placeholder: "Last Name",
		},
		thirdField: {
			placeholder: "Email Address",
		},
		submit: "Subscribe",
		submitLabel: "No spam, we promise",
		thanks: 'You Rock! Thanks for sharing your information! We will be in touch soon with more information about what we do.',
		reset: 'Submit Another'
	}
}