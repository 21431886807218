import styled from 'styled-components'

import theme from '../../../theme/theme'

import { responsiveFontSize } from '../../../util'

const Image = styled.img`
    align-self: center;
    height: ${props => props.height ? props.height : 'auto'};
    max-height: ${props => props.maxHeight ? props.maxHeight : '100%'};
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : '1em'};
    margin-left: ${props => props.marginLeft ? props.marginLeft : '0'};
    margin-right: ${props => props.marginRight ? props.marginRight : '0'};
    margin-top: ${props => props.marginTop ? props.marginTop : 0};
    max-width: ${props => props.maxWidth ? props.maxWidth : '100%'};
    width: ${props => props.width ? props.width : '100%'};

    @media (max-width: ${theme.breakPoints.small}) {
        margin-bottom: ${props => props.marginBottom ? responsiveFontSize(props.marginBottom, 25) : '1em'};
        width: ${props => props.smallWidth ? props.smallWidth : '50%'}
    }

    @media (max-width: ${theme.breakPoints.xsmall}) {
        margin-bottom: 1em;
        width: ${props => props.smallWidth ? props.smallWidth : '50%'}
    }
`

export default Image
