import styled from 'styled-components'

import theme from '../../../theme/theme'

const conditionalAlign = props => props.align ? `align-items: ${props.align};` : null

const conditionalJustify = props => props.justify ? `justify-content: ${props.justify};` : null

const FlexRow = styled.div`
	display: flex;
	${conditionalAlign}
	${conditionalJustify}
	${props => props.noWrap ? 'flex-wrap: nowrap;' : false}
	${props => props.wrap ? 'flex-wrap: wrap;' : false}
	margin-bottom: ${props => props.marginBottom ? props.marginBottom : '1em'};
	margin-left: -15px;
	margin-right: -15px;
	width: ${props => props.width ? props.width : 'inherit'};

	@media (max-width: ${theme.breakPoints.small}) {
		margin-bottom: ${props => props.smallMarginBottom ? props.smallMarginBottom : '1em'};
		margin: 0px auto;
	}

	@media (max-width: ${theme.breakPoints.xsmall}) {
		flex-direction: ${props => props.xsmallNoColumn ? 'initial' : 'column'};
		margin: 0px auto;

	}
`

export default FlexRow
